/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/show/Infos.js
Created On : 13 May 2022 19:17:21
*/

import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { FormGroup } from "../../utilities"

const Infos = ({ data = [] }) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <label>ID</label>
                </Col>
                <Col md={8}>
                  <FormGroup value={data.id} readOnly={true} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Titre</label>
                </Col>
                <Col md={8}>
                  <FormGroup value={data.title} readOnly={true} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Description</label>
                </Col>
                <Col md={8}>
                  <FormGroup
                    as="textarea"
                    value={data.description}
                    inputClassName="mh-200"
                    readOnly={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <label>Date d'ajout</label>
                </Col>
                <Col md={8}>
                  <FormGroup value={data.f_created_at} readOnly={true} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Dernière modification</label>
                </Col>
                <Col md={8}>
                  <FormGroup value={data.f_updated_at} readOnly={true} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Infos
