/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/form/Button.js
Created On : 12 May 2022 16:24:01
*/

import React from "react"
import { Button as BootrstrapButton } from "react-bootstrap"
const Button = ({
  type = "button",
  variant = "primary",
  size = null,
  children,
  className = "",
  isLoading = false,
  disabled = false,
  onClick,
  role,
}) => {
  return (
    <BootrstrapButton
      className={className}
      type={type}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
      role={role}
    >
      {children} {isLoading && <i className="fa-solid fa-spinner fa-spin"></i>}
    </BootrstrapButton>
  )
}

export default Button
