/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/collections/Creator.js
Created On : 26 June 2022 19:10:37
*/
import React, { useState } from "react"
import { Card, Form } from "react-bootstrap"
import { API_STORE_COLLECTION_ROUTE } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyErrors } from "../../../services/Validation"
import { Button, FormGroup } from "../../utilities"

const Creator = ({ onSuccessCallback }) => {
  const [name, setName] = useState("")
  const [caption, setCaption] = useState("")
  const [slug, setSlug] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { makeRequest } = useMakeRequest()
  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: API_STORE_COLLECTION_ROUTE,
      data: {
        name: name,
        caption: caption,
        slug: slug,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: "Collection ajoutée avec succès" })
      setName("")
      setSlug("")
      setCaption("")
      setErrors("")
      if (onSuccessCallback) {
        onSuccessCallback()
      }
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }
  return (
    <Card>
      <Card.Header>
        <Card.Title>Nouvelle collection</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form method="post" onSubmit={onSubmit}>
          <FormGroup
            label="Nom de la collection"
            name="collection"
            value={name}
            onChange={setName}
            error={errors.name}
            disabled={isLoading}
          />
          <FormGroup
            label="Slug"
            name="collection-slug"
            value={slug}
            onChange={setSlug}
            error={errors.slug}
            disabled={isLoading}
          />
          <FormGroup
            label="Caption"
            name="collection-caption"
            value={caption}
            onChange={setCaption}
            error={errors.caption}
            disabled={isLoading}
          />
          <Button
            variant="primary"
            className="w-100"
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Enregister
          </Button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default Creator
