/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/auth/LoginForm.js
Created On : 09 July 2022 13:50:02
*/

import React, { useContext, useState } from "react"
import { Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../../context/AppContext"
import { DASHBOARD } from "../../routes"
import { API_LOGIN_ROUTE, CSRF_ROUTE } from "../../routes/api"
import { useLogin } from "../../services/Auth"
import { useMakeRequest } from "../../services/httpClient"
import { notifyErrors } from "../../services/Validation"
import { Button, FormGroup } from "../utilities"

const LoginForm = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()
  const navigate = useNavigate()
  const login = useLogin()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      route: CSRF_ROUTE,
      setLoader: setIsLoading,
      onSuccess: () => {
        makeRequest({
          method: "post",
          route: API_LOGIN_ROUTE,
          data: {
            email: email,
            password: password,
          },
          setLoader: setIsLoading,
          onSuccess: onSuccess,
          onError: onError,
        })
      },
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      login({ user: response.data.user })
      setErrors([])
      navigate(DASHBOARD)
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div>
      <Form method="post" onSubmit={(e) => onSubmit(e)}>
        <FormGroup
          value={email}
          onChange={setEmail}
          type="email"
          placeholder="E-mail"
          error={errors.email}
          disabled={isLoading}
        />
        <FormGroup
          value={password}
          onChange={setPassword}
          type="password"
          placeholder="Mot de passe"
          error={errors.password}
          disabled={isLoading}
        />
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          className="w-100"
          type="submit"
          variant="secondary"
        >
          Se connecter
        </Button>
      </Form>
    </div>
  )
}

export default LoginForm
