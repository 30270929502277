/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/orders/Manage.js
Created On : 09 June 2022 09:17:54
*/

import React, { useEffect, useState } from "react"
import { Badge, Card, Col, Form, Row, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import CancelOrder from "../../components/orders/Cancel"
import PayOrder from "../../components/orders/Pay"
import MarkAsTreated from "../../components/orders/MarkAsTreated"
import Status from "../../components/orders/Status"
import { Button, FormGroup, Select } from "../../components/utilities"
import { ORDERS_SHOW_ROUTE } from "../../routes"
import { API_ORDERS_ROUTE } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notifyErrors } from "../../services/Validation"
import Overview from "../../components/orders/manage/Overview"
import ActionsManager from "../../components/orders/ActionsManager"

const Manage = () => {
  const [orders, setOrders] = useState({ data: [] })
  const [overview, setOverview] = useState([])
  const [query, setQuery] = useState("")
  const [filter, setFilter] = useState("all")
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [query, filter])

  function fetchData() {
    makeRequest({
      route: API_ORDERS_ROUTE + "?q=" + query + "&filter=" + filter,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setOrders(response.data.orders)
      setOverview(response.data.overview)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  function onSubmit(e) {
    e.preventDefault()
    fetchData(query)
  }

  return (
    <div>
      <Overview data={overview} />
      <Card>
        <Card.Header>
          <Row>
            <Col md={4}>
              <Form onSubmit={onSubmit}>
                <FormGroup
                  type="search"
                  value={query}
                  onChange={setQuery}
                  placeholder="Code / ID"
                  rightPrepend={
                    <Button variant="dark">
                      <i className="fa-solid fa-search"></i>
                    </Button>
                  }
                />
              </Form>
            </Col>
            <Col md={2}>
              <Select value={filter} onChange={setFilter} options={options} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Détails de la commande</th>
                  <th>Sous-total</th>
                  <th>Livraison</th>
                  <th>Total</th>
                  <th>Paiement</th>
                  <th>Status de la commande</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders.data.map((order) => (
                  <tr key={order.id}>
                    <td>
                      <div className="mb-2">
                        <h6 className="small bold">{order.date_for_humans}</h6>
                      </div>
                      <div className="small text-muted">{order.date}</div>
                      {order.f_treated_at && (
                        <Badge pill bg="info">
                          Traité le {order.f_treated_at}
                        </Badge>
                      )}
                    </td>
                    <td>
                      <div className="mb-3">
                        <h6 className="text-primary small bold">{order.id}</h6>
                      </div>
                      <div className="mb-2">
                        <div className="text-muted small">
                          Nom de l'acheteur :<br />
                          <span className="text-primary bold">
                            {order.buyer_name}
                          </span>
                        </div>
                        <div className="text-muted small">
                          Tél :<br />
                          <span className="text-primary bold">
                            {order.customer_phone}
                          </span>
                        </div>
                        <div className="text-muted small">
                          Adresse :<br />
                          <span className="text-primary bold">
                            {order.f_address}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{order.f_subtotal}</td>
                    <td>{order.f_shipping}</td>
                    <td>{order.f_total}</td>
                    <td>
                      <ul className="list-unstyled">
                        {order.f_transactions.map((trans) => (
                          <li key={trans.id}>
                            <div className="text-muted small">
                              ID :{" "}
                              <span className="text-primary bold">
                                {trans.id}
                              </span>
                            </div>
                            <div className="text-muted small">
                              Status :{" "}
                              <span className="text-primary bold">
                                {trans.status}
                              </span>
                            </div>
                            <div className="text-muted small">
                              Mode de paiement :{" "}
                              <span className="text-primary bold">
                                {trans.payment_method}
                              </span>
                            </div>
                            <div className="text-muted small">
                              Référence :{" "}
                              <span className="text-primary bold">
                                {trans.reference}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <Status status={order.f_status} />
                    </td>
                    <td>
                      {order.available_actions && (
                        <ul className="list-unstyled">
                          {order.available_actions.treatable && (
                            <li className="mb-2">
                              <MarkAsTreated
                                onActionSuccess={fetchData}
                                order={order}
                              />
                            </li>
                          )}
                          <li className="mb-2">
                            <Link
                              to={ORDERS_SHOW_ROUTE.replace(":id", order.id)}
                              className="w-100 btn btn-sm btn-outline-dark"
                            >
                              Détails
                            </Link>
                          </li>
                          {order.available_actions.payable && (
                            <li className="mb-2">
                              <PayOrder
                                onActionSuccess={fetchData}
                                order={order}
                              />
                            </li>
                          )}
                          {order.available_actions.cancelable && (
                            <li className="mb-2">
                              <CancelOrder
                                onActionSuccess={fetchData}
                                order={order}
                              />
                            </li>
                          )}
                        </ul>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Manage

const options = [
  { caption: "Tous", value: "all" },
  { caption: "En cours", value: "processing" },
  { caption: "En attente", value: "pending" },
  { caption: "Non traités", value: "untreated" },
  { caption: "Annulées", value: "cancelled" },
]
