/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/App.js
Created On : 12 May 2022 14:06:14
*/

import React from "react"
import Router from "./routes/Router"
import "./styles/app.scss"

import { Toaster } from "react-hot-toast"
const App = () => {
  return (
    <div>
      <Router />
      <Toaster />
    </div>
  )
}

export default App
