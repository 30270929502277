/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/settings/shippings/index.js
Created On : 25 Juillet 2022 12:52:09
*/

import React, { useEffect, useState } from "react"
import { BreadCrumb, Button, ButtonList } from "../../../components/utilities"
import { SHIPPING_PROFILES } from "../../../routes"
import Creator from "../../../components/settings/shippings/createProfile"
import { useMakeRequest } from "../../../services/httpClient"
import { API_SHIPPING_PROFILES } from "../../../routes/api"
import { notifyErrors } from "../../../services/Validation"
import { Card, Table } from "react-bootstrap"

const Index = () => {
  const [showCreateForm, toggleShowCreateForm] = useState(false)
  const [profiles, setProfiles] = useState([])
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_SHIPPING_PROFILES,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setProfiles(response.data.profiles)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <div className="mb-3">
        <ButtonList>
          <Button variant="primary" onClick={() => toggleShowCreateForm(true)}>
            <i className="fa-solid fa-plus"></i> Ajouter un profil
          </Button>
        </ButtonList>
      </div>
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Min</th>
                <th>Max</th>
                <th>Prix local</th>
                <th>Prix local / +</th>
                <th>Prix autres</th>
                <th> Prix autres / +</th>
                <th> Produits</th>
              </tr>
            </thead>
            <tbody>
              {profiles.map((p) => (
                <tr key={p.id}>
                  <td>{p.name}</td>
                  <td>{p.f_min_process_time}</td>
                  <td>{p.f_max_process_time}</td>
                  <td>{p.f_local_price}</td>
                  <td>{p.f_local_additional_price}</td>
                  <td>{p.f_away_price}</td>
                  <td> {p.f_away_additional_price}</td>
                  <td> {p.products_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {showCreateForm && (
        <Creator
          show={showCreateForm}
          onHide={() => toggleShowCreateForm(false)}
          onReload={fetchData}
        />
      )}
    </div>
  )
}

export default Index

const breadcrumb = [
  {
    caption: "Paramètres",
    active: true,
  },
  {
    caption: "Profiles de livraison",
    link: SHIPPING_PROFILES,
    active: true,
  },
]
