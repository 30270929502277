/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/orders/ActionsManager.js
Created On : 29 Juillet 2022 17:50:50
*/

import React from "react"
import CancelOrder from "./Cancel"
import MarkAsTreated from "./MarkAsTreated"
import PayOrder from "./Pay"

const ActionsManager = (props) => {
  const { order } = props
  return (
    <>
      {order.available_actions && (
        <ul className="list-unstyled">
          {order.available_actions.treatable && (
            <li className="mb-2">
              <MarkAsTreated {...props} />
            </li>
          )}
          {order.available_actions.payable && (
            <li className="mb-2">
              <PayOrder {...props} />
            </li>
          )}
          {order.available_actions.cancelable && (
            <li className="mb-2">
              <CancelOrder {...props} />
            </li>
          )}
        </ul>
      )}
    </>
  )
}

export default ActionsManager
