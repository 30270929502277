/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/services/Auth.js
Created On : 04 June 2022 14:16:32
*/

import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setUser } from "../redux/actions/auth"
import { DASHBOARD } from "../routes"
import { API_LOGOUT_ROUTE } from "../routes/api"
import { useMakeRequest } from "./httpClient"

/**
 *
 * @returns LOGOUT
 */
export function useLogout() {
  const login = useLogin()
  const { makeRequest } = useMakeRequest()
  const navigate = useNavigate()
  function logout() {
    makeRequest({
      method: "post",
      route: API_LOGOUT_ROUTE,
      onSuccess: logoutSuccess,
      onError: logoutError,
    })
  }

  function logoutSuccess(response) {
    login({ user: response.data.user })
    navigate(DASHBOARD)
  }

  function logoutError() {
    login({ user: null })
  }

  return logout
}

/**
 *
 * @returns LOGIN
 */
export function useLogin() {
  const dispatch = useDispatch()

  function login({ user }) {
    dispatch(setUser(user))
  }

  return login
}
