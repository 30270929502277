/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Inventory.js
Created On : 17 May 2022 08:40:45
*/

import React from "react"
import { Card, Col, InputGroup, Row } from "react-bootstrap"
import { FormGroup } from "../../utilities"
import VariationsCreator from "./variations/Creator"
import VariationsDisplayer from "./variations/Displayer"

const Inventory = ({
  price = "",
  setPrice,
  oldPrice = "",
  setOldPrice,
  quantity = 1,
  setQuantity,
  mainCurrency,
  errors = [],
  variationTypes,
  variations = { header: [], body: [] },
  setVariations,
  isLoading = false,
}) => {
  function onUpdateVariationsData(data) {
    let currentVariationHeader = variations.header
    setVariations({ header: currentVariationHeader, body: data })
  }

  function onSetVariations(vrs) {
    let variation0 = vrs[0]
    let variation1 = vrs[1]
    let variations_list = []
    let variants0 = variation0.variants
    let variants1 = variation1?.variants
    if (variation1) {
      variants0.forEach((element0) => {
        variants1.forEach((element1) => {
          let line = configureVariationLine(
            variation0,
            element0,
            variation1,
            element1
          )
          variations_list.push(line)
        })
      })
    } else {
      variants0.forEach((element0) => {
        let line = configureVariationLine(variation0, element0)
        variations_list.push(line)
      })
    }
    let mainVariations = {
      header: [variation0, variation1],
      body: variations_list,
    }

    setVariations(mainVariations)
  }

  function configureVariationLine(
    variation0,
    element0,
    variation1 = null,
    element1 = null
  ) {
    let line = {
      first_variation_caption: variation0.caption,
      first_variation_value: variation0.value,
      first_variation_variant_caption: element0.caption,
      first_variation_variant_value: element0.value,
      second_variation_caption: variation1 ? variation1.caption : null,
      second_variation_value: variation1 ? variation1.value : null,
      second_variation_variant_caption: element1 ? element1.caption : null,
      second_variation_variant_value: element1 ? element1.value : null,
      price: "",
      old_price: "",
      quantity: "",
    }
    return line
  }

  return (
    <Card>
      <Card.Header>Inventaire</Card.Header>
      <Card.Body>
        {variations.body.length <= 0 && (
          <Row>
            <Col md={6}>
              <FormGroup
                type="number"
                label="Prix"
                value={price}
                onChange={setPrice}
                name="prix"
                error={errors.price}
                disabled={isLoading || !mainCurrency}
                rightPrepend={
                  <InputGroup.Text>{mainCurrency?.symbol}</InputGroup.Text>
                }
              />
            </Col>

            <Col md={6}>
              <FormGroup
                type="number"
                label="Quantité"
                value={quantity}
                onChange={setQuantity}
                name="quantity"
                error={errors.quantity}
                disabled={isLoading}
              />
            </Col>
            <Col md={4}>
              <FormGroup
                optional={true}
                type="number"
                label="Ancien prix"
                value={oldPrice}
                onChange={setOldPrice}
                name="ancien_prix"
                error={errors.old_price}
                disabled={isLoading || !mainCurrency}
                rightPrepend={
                  <InputGroup.Text>{mainCurrency?.symbol}</InputGroup.Text>
                }
              />
            </Col>
          </Row>
        )}
        <>
          {variations.body?.length > 0 ? (
            <VariationsDisplayer
              mainCurrency={mainCurrency}
              variations={variations}
              onUpdateVariationsData={onUpdateVariationsData}
              errors={errors}
            />
          ) : (
            <></>
          )}
          <VariationsCreator
            error={
              errors.variations ||
              errors["variations.0"] ||
              errors["variations.1"] ||
              errors["variations.0.variants"]
            }
            setVariations={onSetVariations}
            types={variationTypes}
          />
        </>
      </Card.Body>
    </Card>
  )
}

export default Inventory
