/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/orders/Pay.js
Created On : 28 Juillet 2022 11:33:56
*/

import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { API_PAY_OFFLINE_ORDER_ORDER_ROUTE } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notify } from "../../services/Notification"
import { notifyErrors } from "../../services/Validation"
import { Button, FormGroup } from "../utilities"

const Pay = ({ order, onActionSuccess }) => {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [method, setMethod] = useState("")
  const [reference, setReference] = useState("")
  const [date, setDate] = useState("")
  const { makeRequest } = useMakeRequest()

  function confirmOrder() {
    makeRequest({
      method: "post",
      route: API_PAY_OFFLINE_ORDER_ORDER_ROUTE.replace(":id", order?.id),
      data: {
        method: method,
        reference: reference,
        date: date,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      notify({ message: response.data.message })
      onActionSuccess()
      setShow(false)
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div>
      <Button
        variant="success"
        className="w-100"
        onClick={() => {
          setShow(true)
        }}
      >
        Confirmer le paiement
      </Button>
      {show && (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header className="bg-success ">
            <Modal.Title className="text-white">
              Enregistrer un paiement pour la N<sup>0</sup> {order.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              label="Moyen de paiement"
              value={method}
              onChange={setMethod}
              error={errors.method}
            />
            <FormGroup
              label="Numero de référence"
              value={reference}
              onChange={setReference}
              error={errors.reference}
            />
            <FormGroup
              label="Date"
              type="datetime-local"
              value={date}
              onChange={setDate}
              error={errors.date}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="light"
              onClick={() => {
                setShow(false)
              }}
            >
              Annuler
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              variant="success"
              onClick={(e) => {
                confirmOrder(e)
              }}
            >
              Payer la commande
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default Pay
