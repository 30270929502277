/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/Add.js
Created On : 12 May 2022 15:01:19
*/

import React from "react"
import { BreadCrumb } from "../../components/utilities"
import { ADD_LISTING, LISTINGS } from "../../routes"
import CreateForm from "../../components/listings/create/Form"
const Add = () => {
  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <CreateForm />
    </div>
  )
}

export default Add

const breadcrumb = [
  {
    link: LISTINGS,
    caption: "Produits",
  },
  {
    link: ADD_LISTING,
    caption: "Ajouter un produit",
    active: true,
  },
]
