/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/brands/Creator.js
Created On : 30 Juillet 2022 15:31:51
*/

import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { API_BRANDS_STORE } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyErrors } from "../../../services/Validation"
import { Button, FormGroup } from "../../utilities"

const Creator = ({ onSuccessCallback }) => {
  const [name, setName] = useState("")
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: API_BRANDS_STORE,
      data: {
        name: name,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: "Marque ajoutée avec succès" })
      setName("")
      setErrors([])
      if (onSuccessCallback) {
        onSuccessCallback()
      }
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div>
      <Form method="post" onSubmit={onSubmit}>
        <FormGroup
          name="brand"
          label="Nom de la marque"
          value={name}
          onChange={setName}
          error={errors.name}
          disabled={isLoading}
        />

        <Button
          disabled={isLoading}
          isLoading={isLoading}
          type="submit"
          variant="primary"
          className="w-100"
        >
          Enregistrer
        </Button>
      </Form>
    </div>
  )
}

export default Creator
