/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/hooks/Url.js
Created On : 02 April 2022 10:21:25
*/
import { useLocation, useNavigate } from "react-router-dom"

export const useSearchParams = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(location.search)
  function getValue(parameterName) {
    return urlParams.get(parameterName) || ""
  }

  function setValue(parameters, val = null, replace = true) {
    if (Array.isArray(parameters)) {
      parameters.forEach(([parameterName, value]) => {
        appyParameterValue(parameterName, value)
      })
    } else {
      appyParameterValue(parameters, val)
    }

    navigate(
      {
        pathname: location.pathname,
        search: decodeURIComponent(urlParams.toString()),
      },
      { replace: replace }
    )
  }

  function appyParameterValue(parameterName, val) {
    if (!val) {
      urlParams.delete(parameterName)
    } else if (urlParams.has(parameterName)) {
      urlParams.set(parameterName, val)
    } else {
      urlParams.append(parameterName, val)
    }
  }

  function getSearchParamsToString() {
    return decodeURIComponent(urlParams.toString())
  }

  return { getValue, setValue, getSearchParamsToString }
}
