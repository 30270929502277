/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/categories/Creator.js
Created On : 25 June 2022 14:07:13
*/

import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import {
  API_SEARCH_CATEGORY,
  API_STORE_CATEGORY_ROUTE,
} from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyErrors } from "../../../services/Validation"
import { Button, FormGroup, FormGroupWithDropDown } from "../../utilities"

const Creator = ({ onSuccessCallback }) => {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("")
  const [parent, setParent] = useState("")
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: API_STORE_CATEGORY_ROUTE,
      data: {
        name: name,
        image: image?.url,
        parent: parent ? parent.value : null,
        description: description,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: "Catégorie ajoutée avec succès" })
      setName("")
      setErrors([])
      setImage("")
      if (onSuccessCallback) {
        onSuccessCallback()
      }
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div>
      <Form method="post" onSubmit={onSubmit}>
        <FormGroup
          label="Nom de la catégorie"
          value={name}
          onChange={setName}
          error={errors.name}
          disabled={isLoading}
        />
        <FormGroup
          max={80}
          as="textarea"
          label="Description"
          value={description}
          onChange={setDescription}
          error={errors.description}
          disabled={isLoading}
          text={
            description.length < 50
              ? `min 50 caractères, il vous reste ${50 - description.length}`
              : `max 80 caractères, il vous reste ${80 - description.length}`
          }
        />
        <FormGroupWithDropDown
          name="category"
          placeholder="Entrez une catégorie"
          label="Catégorie"
          route={API_SEARCH_CATEGORY}
          onChange={setParent}
          selectedValue={parent ? parent.subcaption : null}
          error={errors.parent}
          setIsLoading={isLoading}
        />
        <Row className="mb-3">
          <Col md={image ? 6 : 12}>
            <FormGroup
              label="Image"
              type="file"
              accept="image/*"
              onChange={setImage}
              error={errors.image}
              disabled={isLoading}
            />
          </Col>
          {image && (
            <Col md={6}>
              <img src={image?.url} alt="" className="w-100 img-thumbnail" />
            </Col>
          )}
        </Row>

        <Button
          disabled={isLoading}
          isLoading={isLoading}
          type="submit"
          variant="primary"
          className="w-100"
        >
          Enregistrer
        </Button>
      </Form>
    </div>
  )
}

export default Creator
