/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/finances/Currencies.js
Created On : 14 May 2022 10:23:19
*/

import React, { useEffect, useState } from "react"
import { Badge, Card, Col, Row, Table } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  BreadCrumb,
  Button,
  ButtonList,
  Select,
  Switch,
} from "../../../components/utilities"
import {
  ADD_CURRENCY,
  FINANCES_CURRENCIES,
  SHOW_CURRENCY,
} from "../../../routes"
import {
  CURRENCIES,
  SET_MAIN_CURRENCY,
  TOGGLE_CURRENCY_STATUS,
} from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyError } from "../../../services/Validation"

const Currencies = () => {
  const [currencies, setCurrencies] = useState([])
  const [mainCurrenciesList, setMainCurrenciesList] = useState([])
  const [mainCurrency, setMainCurrency] = useState(null)
  const [currentMainCurrency, setCurrentMainCurrency] = useState(null)
  const { makeRequest, makeAction } = useMakeRequest()

  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: CURRENCIES,
      onSuccess: onFetchSuccess,
      onError: onFetchError,
    })
  }

  function onFetchSuccess(response) {
    if (response.status === 200) {
      setCurrencies(response.data.currencies)
      setMainCurrenciesList(response.data.main_currencies_list)
      setCurrentMainCurrency(response.data.main_currency)
      setMainCurrency(response.data.main_currency)
    }
  }

  function onFetchError(error) {
    notifyError(error)
  }

  function onSetMainCurrency() {
    if (mainCurrency) {
      makeAction({
        method: "post",
        route: SET_MAIN_CURRENCY.replace(":id", mainCurrency),
        onSuccess: fetchData,
      })
    }
  }

  function toggleCurrencyStatus(e, id) {
    e.preventDefault()
    makeAction({
      method: "post",
      route: TOGGLE_CURRENCY_STATUS.replace(":id", id),
      onSuccess: fetchData,
    })
  }
  function redirectTo(e, url) {
    e.preventDefault()
    navigate({ pathname: url })
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <ButtonList>
        <Link to={ADD_CURRENCY} className="btn btn-primary">
          <i className="fa-solid fa-plus"></i> Créer
        </Link>
      </ButtonList>
      <h1 className="s_title__01 mb-3">Dévises</h1>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th>Dévise</th>
                    <th>Symbole</th>
                    <th>Nom</th>
                    <th>Dernière mise à jour</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {currencies.map((currency) => (
                    <tr
                      key={currency.id}
                      onClick={(e) =>
                        redirectTo(e, SHOW_CURRENCY.replace(":id", currency.id))
                      }
                    >
                      <td>
                        {currency.currency}{" "}
                        {currency.is_main ? (
                          <Badge pill bg="success">
                            Principale
                          </Badge>
                        ) : null}
                      </td>
                      <td>{currency.symbol}</td>
                      <td>{currency.name}</td>
                      <td>{currency.f_created_at}</td>
                      <td>
                        <Switch
                          checked={currency.is_active}
                          value={currency.is_active}
                          id={"active-" + currency.id}
                          onClick={(e) => {
                            toggleCurrencyStatus(e, currency.id)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>Devise principale</Card.Header>
            <Card.Body>
              <Select
                options={mainCurrenciesList}
                value={mainCurrency}
                onChange={setMainCurrency}
                id="main-currency"
              />
            </Card.Body>
            <Card.Footer>
              <ButtonList>
                <Button
                  variant="info"
                  disabled={
                    !mainCurrency || currentMainCurrency === mainCurrency
                  }
                  onClick={onSetMainCurrency}
                >
                  Mettre à jour
                </Button>
              </ButtonList>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Currencies

const breadcrumb = [
  {
    caption: "Finances",
    active: true,
  },
  {
    caption: "Devises",
    link: FINANCES_CURRENCIES,
    active: true,
  },
]
