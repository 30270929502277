/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/layouts/SideMenus.js
Created On : 12 May 2022 14:36:47
*/

import React from "react"
import { NavLink } from "react-router-dom"
import * as routes from "../routes"
const SideMenus = () => {
  const Section = ({ title, items = [] }) => {
    return (
      <section>
        {title && (
          <h3 className="section--title">
            <i className="fas fa-caret-right"></i> {title}
          </h3>
        )}
        <ul className="menu-list">
          {items.map((item, key) => (
            <li key={key}>
              <NavLink
                to={item.url}
                className={(navData) =>
                  navData.isActive ? "active menu-item" : " menu-item "
                }
              >
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </section>
    )
  }

  return (
    <div className="msm--01">
      <Section
        title="Stats"
        items={[
          { title: "Tableau de bord", url: routes.DASHBOARD },
          { title: "Termes de recherche", url: routes.STATS_SEARCH_TERMS },
        ]}
      />
      <Section
        title="Commandes"
        items={[{ title: "Commandes", url: routes.ORDERS_MANAGE_ROUTE }]}
      />
      <Section
        title="Produits"
        items={[
          { title: "Ajouter un produit", url: routes.ADD_LISTING },
          { title: "Produits", url: routes.LISTINGS },
          { title: "Catégories", url: routes.CATEGORIES_ROUTE },
          { title: "Brands", url: routes.BRANDS_ROUTE },
          { title: "Collections", url: routes.COLLECTIONS_ROUTE },
          {
            title: "Type de variations",
            url: routes.LISTINGS_VARIATIONS_TYPES,
          },
        ]}
      />
      <Section
        title="Finances"
        items={[
          { title: "Dévises", url: routes.FINANCES_CURRENCIES },
          {
            title: "Méthodes de paiement",
            url: routes.FINCANCES_PAYMENT_METHODS_ROUTE,
          },
        ]}
      />
      <Section
        title="Réglages"
        items={[
          { title: "Pays et Régions", url: routes.COUNTIRES_REGIONS },
          { title: "Profils de livraison", url: routes.SHIPPING_PROFILES },
        ]}
      />
    </div>
  )
}

export default SideMenus
