/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/settings/Countries.js
Created On : 06 June 2022 17:12:35
*/

import React, { useState, useEffect } from "react"
import { BreadCrumb, Button, ButtonList } from "../../components/utilities"
import { COUNTIRES_REGIONS, COUNTIRES_REGIONS_SHOW } from "../../routes"
import CountriesCreator from "../../components/settings/countries/Creator"
import { Card, Table } from "react-bootstrap"
import { useMakeRequest } from "../../services/httpClient"
import { API_COUNTRIES } from "../../routes/api"
import { notifyErrors } from "../../services/Validation"
import { Link } from "react-router-dom"

const Countries = () => {
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [countries, setCountries] = useState([])
  const { makeRequest } = useMakeRequest()

  function toggleShowCreateForm(status) {
    setShowCreateForm(status)
  }

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_COUNTRIES,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setCountries(response.data.countries)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <div className="mb-3">
        <ButtonList>
          <Button variant="primary" onClick={() => toggleShowCreateForm(true)}>
            <i className="fa-solid fa-plus"></i> Ajouter un Pays
          </Button>
        </ButtonList>
      </div>

      <Card>
        <Card.Body>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Code tél.</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {countries.map((country) => (
                  <tr key={country.id}>
                    <td>{country.name}</td>
                    <td>{country.code}</td>
                    <td>
                      <Link
                        to={COUNTIRES_REGIONS_SHOW.replace(":id", country.id)}
                        className="btn btn-sm btn-success"
                      >
                        {" "}
                        Détails{" "}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <CountriesCreator
        show={showCreateForm}
        onHide={() => toggleShowCreateForm(false)}
      />
    </div>
  )
}

export default Countries

const breadcrumb = [
  {
    caption: "Paramètres",
    active: true,
  },
  {
    caption: "Pays et Régions",
    link: COUNTIRES_REGIONS,
    active: true,
  },
]
