/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/show/Photos.js
Created On : 24 May 2022 20:15:24
*/

import React from "react"
import { Card, Col, Row } from "react-bootstrap"

const Photos = ({ listing }) => {
  const ImageDisplayer = ({ img }) => {
    return (
      <div className="lcf--img-displayer">
        <img src={img.url} alt="" />
        {img.is_default ? (
          <span className="default-tag">Par défaut</span>
        ) : (
          <></>
        )}
      </div>
    )
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            {listing.photos.map((img, key) => (
              <Col md={2}>
                <ImageDisplayer key={key} img={img} />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Photos
