/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/finances/payement-methods/Create.js
Created On : 29 May 2022 11:53:27
*/

import React, { useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import {
  BreadCrumb,
  Button,
  ButtonList,
  FormGroup,
  Switch,
} from "../../../components/utilities"
import {
  FINCANCES_ADD_PAYMENT_METHODS_ROUTE,
  FINCANCES_PAYMENT_METHODS_ROUTE,
} from "../../../routes"
import { STORE_PAYMENT_METHOD } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyErrors } from "../../../services/Validation"

const Create = () => {
  const [name, setName] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: STORE_PAYMENT_METHOD,
      data: { name: name, is_active: isActive },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      resetData()
    }
  }

  function resetData() {
    setErrors([])
    setName("")
    setIsActive(false)
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Row>
        <Col md={6}>
          <Card>
            <Form method="put" onSubmit={onSubmit}>
              <Card.Header>Ajouter une méthode de paiement</Card.Header>
              <Card.Body>
                <FormGroup
                  name="payment-method"
                  disabled={isLoading}
                  label="Nom"
                  value={name}
                  onChange={setName}
                  error={errors.name}
                  required={true}
                />
                <Switch
                  disabled={isLoading}
                  label="Activer"
                  id="status"
                  value={isActive}
                  onChange={setIsActive}
                  error={errors.is_active}
                />
              </Card.Body>
              <Card.Footer>
                <ButtonList>
                  <Link
                    disabled={isLoading}
                    to={FINCANCES_PAYMENT_METHODS_ROUTE}
                    className="btn btn-light"
                  >
                    Annuler
                  </Link>
                  <Button
                    variant="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                    type="submit"
                  >
                    Enregister
                  </Button>
                </ButtonList>
              </Card.Footer>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Create

const breadcrumb = [
  {
    caption: "Finances",
    link: "/",
    active: true,
  },
  {
    caption: "Méthodes de paiement",
    link: FINCANCES_PAYMENT_METHODS_ROUTE,
  },
  {
    caption: "Ajouter",
    link: FINCANCES_ADD_PAYMENT_METHODS_ROUTE,
    active: true,
  },
]
