/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/variations/VariationConfig.js
Created On : 19 May 2022 16:54:54
*/

import React from "react"
import { Col, Row } from "react-bootstrap"
import { Button, Checkbox, Select } from "../../../utilities"

const VariationConfig = ({
  variation,
  setVariants,
  removeVariation,
  updateVariation,
  index,
}) => {
  //
  function onAddVariant(variantId) {
    let variantList = [...variation["variant_list"]]
    let variantVariable = variantList.find(
      (item) => item.value === parseInt(variantId)
    )
    let variants = [...variation["variants"]]
    variants.push(variantVariable)
    setVariants(variants)
  }
  //
  function removeVariant(index) {
    let variants = [...variation["variants"]]
    variants.splice(index, 1)
    setVariants(variants)
  }

  function canBeAdded(option) {
    let variants = [...variation["variants"]]
    return !variants.includes(option)
  }

  function onUpdatePriceOption(value) {
    let v = variation
    v.price_varies = value
    updateVariation(v)
  }

  function onUpdateQuantityOption(value) {
    let v = variation
    v.quantity_varies = value
    updateVariation(v)
  }

  return (
    <Row>
      <Col md={6}>
        <div className="cv-m--displayer">
          <h1 className="type--caption">
            {variation?.caption}{" "}
            <small className="text-underline" onClick={() => removeVariation()}>
              Supprimer
            </small>
          </h1>
          <div className="options">
            <ul>
              <li>
                <Checkbox
                  onChange={onUpdatePriceOption}
                  checked={variation.price_varies}
                  value={variation.price_varies}
                  label={`Le prix varie pour chaque ${variation?.caption}`}
                  id={`price-option-${index}`}
                />
              </li>
              <li>
                <Checkbox
                  onChange={onUpdateQuantityOption}
                  checked={variation.quantity_varies}
                  value={variation.quantity_varies}
                  label={`La quantité varie pour chaque ${variation?.caption}`}
                  id={`quantity-option-${index}`}
                />
              </li>
            </ul>
          </div>
        </div>
      </Col>
      <Col md={6}>
        <Select
          placeholder={`Choisir ${variation?.caption}`}
          options={variation.variant_list.filter(canBeAdded)}
          onChange={onAddVariant}
        ></Select>
        {variation?.variants.map((v, k) => (
          <div className="variant-ading--displayer--01" key={k}>
            <div className="caption--c">{v.caption}</div>
            <Button
              variant="light"
              className="remover"
              onClick={() => removeVariant(k)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        ))}
      </Col>
    </Row>
  )
}

export default VariationConfig
