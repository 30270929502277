/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/Edit.js
Created On : 13 May 2022 20:14:21
*/

import React, { useContext, useEffect, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import Infos from "../../components/listings/create/Infos"
import Inventory from "../../components/listings/create/Inventory"
import { BreadCrumb, Button } from "../../components/utilities"
import { AppContext } from "../../context/AppContext"
import { LISTINGS, LISTING_EDIT, LISTING_SHOW } from "../../routes"
import { EDIT_LISTING, UPDATE_LISTING } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notify } from "../../services/Notification"
import { notifyError, setValidationErrors } from "../../services/Validation"

const Edit = () => {
  const { id } = useParams()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [price, setPrice] = useState("")
  const [quantity, setQuantity] = useState("")
  const [variations, setVariations] = useState([])
  const [variationTypes, setVariationsTypes] = useState()

  const [currency, setCurrency] = useState("")
  const [errors, setErrors] = useState([])
  const { isLoading } = useContext(AppContext)
  const navigate = useNavigate()
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [id])

  function fetchData() {
    makeRequest({
      route: EDIT_LISTING.replace(":id", id),
      onSuccess: onFetchSuccess,
      onError: onFetchError,
    })
  }

  function onFetchSuccess(response) {
    if (response.status === 200) {
      let listing = response.data.listing
      setTitle(listing.title)
      setDescription(listing.description)
      setPrice(listing.price)
      setQuantity(listing.quantity)
      setCurrency(response.data.currency)
    }
  }

  function onFetchError(error) {
    notifyError(error)
  }

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "post",
      route: UPDATE_LISTING.replace(":id", id),
      data: {
        id: id,
        title: title,
        description: description,
        price: price,
        quantity: quantity,
        currency_id: currency?.id,
      },
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      notify({ message: response.data.message })
      resetData()
    }
  }

  function resetData() {
    setErrors([])
    fetchData()
  }

  function onError(error) {
    setValidationErrors(setErrors, error)
  }

  function abort() {
    navigate(LISTINGS)
  }
  const breadcrumb = [
    {
      link: LISTINGS,
      caption: "Produits",
    },
    {
      link: LISTING_SHOW.replace(":id", id),
      caption: "#" + id,
    },
    {
      link: LISTING_EDIT.replace(":id", id),
      caption: "Modifier le produit",
      active: true,
    },
  ]
  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Form method="post" onSubmit={onSubmit}>
        <Row>
          <Col md={6}>
            <div>
              <h1 className="s_title__01 mb-3">Modifier - #{id} </h1>
              <div>
                <Infos
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                  errors={errors}
                  isLoading={isLoading}
                />
                <Inventory
                  price={price}
                  setPrice={setPrice}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  mainCurrency={currency}
                  errors={errors}
                  isLoading={isLoading}
                  variationTypes={variationTypes}
                  variations={variations}
                  setVariations={setVariations}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Header>Finaliser</Card.Header>
              <Card.Body>
                <div className="flex-space-b">
                  <Button
                    variant="light"
                    onClick={abort}
                    role="button"
                    disabled={isLoading}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="info"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Mettre à jour
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Edit
