/*
Author : Mateo Mirindi
Company : Numerik
Fichier : components/utilities/ux/Pagination.js
Created On : 29 Juillet 2022 13:30:48
*/

import React from "react"
import { Pagination } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom"

const CustomPagination = ({ data = {} }) => {
  const query = useSearchParams()
  const navigate = useNavigate()
  // const router = useRouter()

  function goToPage(e, p) {
    e.preventDefault()
    console.log()
    // let query = router.query
    // query.page = p
    // navigate({ pathname: router.pathname, query: query })
  }

  return (
    <div className="flex-center">
      <div className="">
        <Pagination>
          <Pagination.First
            onClick={(e) => goToPage(e, 1)}
            disabled={data.current_page <= 1}
          />
          <Pagination.Prev
            onClick={(e) => goToPage(e, data.current_page - 1)}
            disabled={!data.prev_page_url}
          />

          {[...Array(data.last_page)].map((pg, value) => (
            <Pagination.Item
              key={value}
              active={value + 1 === data.current_page}
              onClick={(e) => goToPage(e, value + 1)}
            >
              {value + 1}
            </Pagination.Item>
          ))}

          <Pagination.Next
            onClick={(e) => goToPage(e, data.current_page + 1)}
            disabled={!data.next_page_url}
          />
          <Pagination.Last
            onClick={(e) => goToPage(e, data.last_page)}
            disabled={data.current_page >= data.last_page}
          />
        </Pagination>
      </div>
    </div>
  )
}

export default CustomPagination
