/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/variations/display/ItemLine.js
Created On : 28 June 2022 11:30:38
*/

import React from "react"
import { Badge, InputGroup } from "react-bootstrap"
import { FormGroup } from "../../../../utilities"

const ItemLine = ({
  variation,
  hasSecondVariation,
  mainCurrency,
  onPriceUpdated,
  onOldPriceUpdated,
  onRemoveItem,
  onQuantityUpdated,
  idx,
  errors = [],
}) => {
  return (
    <tr>
      <td>
        {variation.first_variation_variant_caption}{" "}
        {errors[`variations.${idx}.first_variation_variant_value`] && (
          <div>
            <span className="text-danger">
              {errors[`variations.${idx}.first_variation_variant_value`]}
            </span>
          </div>
        )}
      </td>
      {hasSecondVariation && (
        <td>
          {variation.second_variation_variant_caption}
          {errors[`variations.${idx}.second_variation_variant_value`] && (
            <div>
              <span className="text-danger">
                {errors[`variations.${idx}.second_variation_variant_value`]}
              </span>
            </div>
          )}
        </td>
      )}
      <td>
        <div className="mw-200">
          <FormGroup
            error={errors[`variations.${idx}.price`]}
            type="number"
            name="prix"
            value={variation.price}
            onChange={onPriceUpdated}
            size="sm"
            rightPrepend={
              <InputGroup.Text>{mainCurrency?.symbol}</InputGroup.Text>
            }
          />
        </div>
      </td>
      <td>
        <div className="mw-200">
          <FormGroup
            error={errors[`variations.${idx}.quantity`]}
            type="number"
            value={variation.quantity}
            onChange={onQuantityUpdated}
            name="quantity"
          />
        </div>
      </td>
      <td>
        <div className="mw-200">
          <FormGroup
            error={errors[`variations.${idx}.old_price`]}
            type="number"
            name="Ancien prix"
            value={variation.old_price}
            onChange={onOldPriceUpdated}
            size="sm"
            rightPrepend={
              <InputGroup.Text>{mainCurrency?.symbol}</InputGroup.Text>
            }
          />
        </div>
      </td>
      <td>
        <Badge pill bg="danger" onClick={() => onRemoveItem()}>
          <i className="fa-solid fa-trash-alt"></i>{" "}
        </Badge>{" "}
      </td>
    </tr>
  )
}

export default ItemLine
