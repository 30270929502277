/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/orders/Show.js
Created On : 23 Juillet 2022 18:45:45
*/

import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { BreadCrumb } from "../../components/utilities"
import { ORDERS_MANAGE_ROUTE, ORDERS_SHOW_ROUTE } from "../../routes"
import { API_FETCH_ORDER_DETAILS } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notifyErrors } from "../../services/Validation"

import OrderPaidBadge from "../../components/orders/PaidBadge"
import Status from "../../components/orders/Status"
import OrderInfosIndicator from "../../components/orders/InfosIndicator"
import { Alert, Badge, Card, Col, Row, Table } from "react-bootstrap"
import ActionsManager from "../../components/orders/ActionsManager"

const Show = () => {
  const { id } = useParams()
  const [order, setOrder] = useState(null)
  const [transaction, setTransaction] = useState([])
  const [items, setItems] = useState([])
  const { makeRequest } = useMakeRequest()
  const breadcrumb = [
    {
      link: ORDERS_MANAGE_ROUTE,
      caption: "Commandes",
    },
    {
      link: ORDERS_SHOW_ROUTE.replace(":id", id),
      caption: "#" + id,
      active: true,
    },
  ]

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    makeRequest({
      route: API_FETCH_ORDER_DETAILS.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setOrder(response.data.order)
      setItems(response.data.items)
      setTransaction(response.data.transaction)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div className="order-show--page">
      <BreadCrumb items={breadcrumb} />
      {order && (
        <>
          <div className="hdr">
            <div className="s--title">
              Commande N<sup>o</sup> <span>{order.id}</span>
            </div>
            {order.paid_at && <OrderPaidBadge />}
            <Status status={order.status} />
            {order.treated_at && (
              <Badge pill bg="success">
                Traité <i className="fa-solid fa-check-circle"></i>
              </Badge>
            )}
          </div>
          <Row>
            <Col md={8}>
              <Row>
                <Col md={12}>
                  <Card className="mb-3">
                    <Card.Header>Articles</Card.Header>
                    <Card.Body>
                      {items.map((item) => (
                        <div key={item.id} className="item-displayer">
                          <div className="image-container">
                            <img src={item.image} alt={item.tile} />
                          </div>
                          <div className="details-container">
                            <div className="infos">
                              <h3 className="i--name">{item.title}</h3>

                              <OrderInfosIndicator item={item} />
                            </div>
                            <div className="unit-price">
                              {item.f_single_price}
                            </div>
                            <div className="quantity">{item.quantity}</div>
                            <div className="total">{item.f_total_price}</div>
                          </div>
                          <a
                            href={item.supplier_link}
                            title="Lien fournisseur"
                            target="_blank"
                            className="supplier-link btn btn-sm btn-outline-info"
                            rel="noreferrer"
                          >
                            <i className="fa-solid fa-link"></i>
                          </a>
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="mb-3">
                    <Card.Header>Paiement</Card.Header>
                    <Card.Body>
                      <Table>
                        <tbody>
                          <tr>
                            <th>Status</th>
                            <td>
                              {" "}
                              {transaction.paid_at ? (
                                <OrderPaidBadge />
                              ) : (
                                <span className="text-danger">Non</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Montant Payé</th>
                            <td className="text-success">
                              {transaction.f_amount}{" "}
                            </td>
                          </tr>
                          <tr>
                            <th>Référence</th>
                            <td>{transaction.reference} </td>
                          </tr>
                          <tr>
                            <th>Methode</th>
                            <td>{transaction.payment_method}</td>
                          </tr>
                          <tr>
                            <th>Mode de paiement</th>
                            <td>{transaction.payment_mode}</td>
                          </tr>
                          <tr>
                            <th>ID paiement</th>
                            <td>{transaction.id}</td>
                          </tr>
                          <tr>
                            <th>Date </th>
                            <td>{transaction.paid_at}</td>
                          </tr>
                          <tr>
                            <th>Sous-total </th>
                            <td>{order.f_subtotal} </td>
                          </tr>
                          <tr>
                            <th>Livraison </th>
                            <td>{order.f_shipping} </td>
                          </tr>
                          <tr>
                            <th>Total </th>
                            <td>{order.f_total} </td>
                          </tr>
                          <tr>
                            <th>Créer le </th>
                            <td>{transaction.f_created_at} </td>
                          </tr>
                          <tr>
                            <th>Dernière mise à jour </th>
                            <td>{transaction.f_updated_at} </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Header>Actions</Card.Header>
                    <Card.Body>
                      <ActionsManager
                        order={order}
                        onActionSuccess={fetchData}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Header>Client</Card.Header>
                <Card.Body>
                  <div className="client-infos">
                    <div className="avatar-holder"></div>
                    <div className="">{order.customer_name}</div>
                    <div className="">{order.customer_email}</div>
                    <div className="">{order.f_phone}</div>
                  </div>
                  <hr />
                  <h6>Date et adresse de livraison</h6>
                  <Alert variant="warning">{order.shipping_range}</Alert>
                  <div className="text-muted small">
                    <div className="a--address">{order.address}</div>
                    <div className="a--country">{order.country_name}</div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default Show
