/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/finances/currencies/AddRate.js
Created On : 14 May 2022 20:28:13
*/
import { Card, Col, Form, Modal, Row } from "react-bootstrap"
import React, { useState, useEffect } from "react"
import { Button, ButtonList, FormGroup } from "../../utilities"
import { CREATE_EXCHANGE_RATE, STORE_EXCHANGE_RATE } from "../../../routes/api"
import { setValidationErrors } from "../../../services/Validation"
import { notify } from "../../../services/Notification"
import { useMakeRequest } from "../../../services/httpClient"

const AddRate = ({ currency, toggleVisible, visible }) => {
  const [date, setDate] = useState("")
  const [unitPer, setUnitPer] = useState("")
  const [perUnit, setPerUniut] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [mainCurrency, setMainCurrency] = useState(null)
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: CREATE_EXCHANGE_RATE,
      onSuccess: onFetchSuccess,
      onError: onFetchError,
    })
  }
  function onFetchSuccess(response) {
    if (response.status === 200) {
      setMainCurrency(response.data.main_currency)
    }
  }

  function onFetchError(error) {
    setValidationErrors(setErrors, error)
  }

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: STORE_EXCHANGE_RATE,
      data: {
        currency: currency?.id,
        unit_per: unitPer,
        per_unit: perUnit,
        date: date,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      resetData()
      toggleVisible(false)
    }
  }

  function resetData() {
    setDate("")
    setUnitPer("")
    setPerUniut("")
    setIsLoading(false)
    setErrors([])
  }

  function onError(error) {
    setValidationErrors(setErrors, error)
  }

  return (
    <Modal show={visible} onHide={() => toggleVisible(false)}>
      <Modal.Body>
        <Form method="post" onSubmit={onSubmit}>
          <Card.Title>Taux d'échange</Card.Title>
          <Modal.Body>
            <FormGroup
              label="Devise"
              disabled={true}
              value={currency?.currency}
              error={errors.currency}
            />
            <FormGroup
              type="date"
              label="Date"
              disabled={isLoading}
              value={date}
              onChange={setDate}
              error={errors.date}
            />
            <Row>
              <Col md={6}>
                <FormGroup
                  label={`1 ${mainCurrency?.symbol} Par ${currency?.symbol}`}
                  type="number"
                  disabled={isLoading}
                  value={unitPer}
                  onChange={setUnitPer}
                  error={errors.unit_per}
                />
              </Col>
              <Col md={6}>
                <FormGroup
                  label={`1 ${currency?.symbol} Par ${mainCurrency?.symbol}`}
                  type="number"
                  disabled={isLoading}
                  value={perUnit}
                  onChange={setPerUniut}
                  error={errors.per_unit}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ButtonList>
              <Button
                variant="light"
                role="button"
                disabled={isLoading}
                onClick={() => toggleVisible(false)}
              >
                Annuler
              </Button>
              <Button
                variant="success"
                type="submit"
                role="button"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Enregister
              </Button>
            </ButtonList>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddRate
