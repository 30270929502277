/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/settings/provinces/Creator.js
Created On : 06 June 2022 18:40:53
*/
import React, { useContext, useState } from "react"
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { AppContext } from "../../../../context/AppContext"
import { API_STORE_PROVINCE } from "../../../../routes/api"
import { useMakeRequest } from "../../../../services/httpClient"
import { notify } from "../../../../services/Notification"
import { notifyErrors } from "../../../../services/Validation"
import { Button, FormGroup, Select } from "../../../utilities"
import Input from "../../../utilities/form/Input"

const Creator = ({ show, onHide, country, onSuccessCallback }) => {
  const [name, setName] = useState("")
  const [errors, setErrors] = useState([])
  const { makeRequest } = useMakeRequest()
  const { isLoading } = useContext(AppContext)

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: API_STORE_PROVINCE,
      data: { name: name, country: country?.id },
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      setName("")
      setErrors([])
      if (onSuccessCallback) {
        onSuccessCallback()
      }
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form method="post " onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Ajouter une province</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {country && (
            <Select
              label="Pays"
              value={country?.id}
              options={[{ caption: country?.name, value: country?.id }]}
            />
          )}
          <FormGroup
            value={name}
            onChange={setName}
            error={errors.name}
            label="Nom de la province"
            name="province"
            required={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide()}>
            Annuler
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            variant="primary"
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default Creator
