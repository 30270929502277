/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Shipping.js
Created On : 25 Juillet 2022 15:10:22
*/

import React from "react"
import { Badge, Card, Col, Row, Table } from "react-bootstrap"
import { Button, FormGroup } from "../../utilities"

const Shipping = ({
  shippingProfile,
  setShippingProfile,
  shippingProfiles = [],
  shippedFrom,
  setShippedFrom,
  errors = [],
}) => {
  return (
    <Card>
      <Card.Header>
        Livraison
        {errors.shipping_profile && (
          <div className="my-1 text-danger small">
            {errors.shipping_profile}{" "}
          </div>
        )}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <FormGroup
              label="Origine du produit"
              value={shippedFrom}
              onChange={setShippedFrom}
              error={errors.shipped_from}
              text="États-Unis"
              name="shipped-from"
            />
          </Col>
        </Row>

        <Table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Min</th>
              <th>Max</th>
              <th>Prix local</th>
              <th>Prix local / +</th>
              <th>Prix autres</th>
              <th> Prix autres / +</th>
              <th> Produits</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {shippingProfiles.map((p) => (
              <tr key={p.id}>
                <td>{p.name}</td>
                <td>{p.f_min_process_time}</td>
                <td>{p.f_max_process_time}</td>
                <td>{p.f_local_price}</td>
                <td>{p.f_local_additional_price}</td>
                <td>{p.f_away_price}</td>
                <td> {p.f_away_additional_price}</td>
                <td> {p.products_count}</td>
                <td>
                  {shippingProfile?.id === p.id ? (
                    <Badge pill bg="success">
                      Selectionné <i className="fa-solid fa-check-circle"></i>
                    </Badge>
                  ) : (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => setShippingProfile(p)}
                    >
                      Choisir
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default Shipping
