/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/constants/httpCodes.js
Created On : 14 May 2022 10:01:07
*/

export const NETWORK_ERROR = "ERR_NETWORK"
export const FORBIDDEN = 403
export const UNAUTHORIZED = 401
