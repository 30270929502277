/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/form/Checkbox.js
Created On : 14 May 2022 11:51:37
*/

import React from "react"
import { Form } from "react-bootstrap"

const Checkbox = ({
  type = "checkbox",
  label,
  id,
  value = false,
  onChange,
  error,
  disabled,
  name,
  checked,
}) => {
  const setOnChange = (e) => {
    if (type === "radio") {
      onChange(e.target.value)
      return
    }
    onChange(e.target.checked)
    return
  }
  return (
    <Form.Check
      name={name}
      type={type}
      id={id}
      label={label}
      value={value}
      onChange={setOnChange}
      isInvalid={error}
      disabled={disabled}
      checked={checked}
    />
  )
}
export default Checkbox
