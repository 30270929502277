/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/variations-types/Show.js
Created On : 17 May 2022 13:49:57
*/

import React, { useEffect, useState } from "react"
import { Badge, Card, Col, Row, Tab, Table, Tabs } from "react-bootstrap"
import { useParams } from "react-router-dom"
import {
  BreadCrumb,
  Button,
  ButtonList,
  FormGroup,
} from "../../../components/utilities"
import {
  LISTINGS,
  LISTINGS_VARIATIONS_TYPES,
  LISTINGS_VARIATIONS_TYPE_SHOW,
} from "../../../routes"
import {
  DELETE_LISTINGS_VARIATIONS_TYPE_VARIANT,
  SHOW_LISTINGS_VARIATIONS_TYPE,
} from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyError } from "../../../services/Validation"
import CreateForm from "../../../components/listings/variations-types/variants/Create"

const Show = () => {
  const { id } = useParams()
  const [type, setType] = useState(null)
  const [variants, setVariants] = useState([])
  const [createVariant, setCreateVariant] = useState(false)

  const { makeRequest, makeAction } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: SHOW_LISTINGS_VARIATIONS_TYPE.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setType(response.data.type)
      setVariants(response.data.variants)
    }
  }

  function onError(error) {
    notifyError(error)
  }

  //
  function deleteVariant(id) {
    makeAction({
      method: "post",
      route: DELETE_LISTINGS_VARIATIONS_TYPE_VARIANT.replace(":id", id),
      onSuccess: fetchData,
    })
  }

  const breadcrumb = [
    {
      link: LISTINGS,
      caption: "Produits",
    },
    {
      link: LISTINGS_VARIATIONS_TYPES,
      caption: "Types de variations",
    },
    {
      link: LISTINGS_VARIATIONS_TYPE_SHOW.replace(":id", id),
      caption: "Voir #" + id,
      active: true,
    },
  ]

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      {type ? <h1 className="s_title__01 mb-3">{type.type}</h1> : <></>}
      <Card>
        <Card.Body>
          {type ? (
            <>
              <Row>
                <Col md={4}>
                  <label>ID</label>
                </Col>
                <Col md={8}>
                  <FormGroup value={type.id} readOnly={true} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Type</label>
                </Col>
                <Col md={8}>
                  <FormGroup value={type.type} readOnly={true} />
                </Col>
              </Row>{" "}
            </>
          ) : (
            <></>
          )}
          <Tabs defaultActiveKey="variants" id="variants" className="mb-3">
            <Tab eventKey="variants" title="Variantes">
              <ButtonList>
                <Button
                  variant="primary"
                  onClick={() => setCreateVariant(true)}
                >
                  Ajouter un variant
                </Button>
                {createVariant ? (
                  <CreateForm
                    show={createVariant}
                    onHide={() => setCreateVariant(false)}
                    title={`Ajouter une ${type?.type}`}
                    id={type?.id}
                    onStoreSuccess={fetchData}
                  />
                ) : (
                  <></>
                )}
              </ButtonList>
              <Table>
                <thead>
                  <tr>
                    <th>Variant</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {variants.map((v) => (
                    <tr key={v.id}>
                      <td>{v.name}</td>
                      <td>
                        <Badge
                          pill
                          bg="danger"
                          onClick={() => deleteVariant(v.id)}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Show
