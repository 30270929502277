import React, { useEffect, useState } from "react"
import { Card, Col, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Creator from "../../../components/listings/collections/Creator"
import { BreadCrumb, Switch } from "../../../components/utilities"
import {
  COLLECTIONS_ROUTE,
  COLLECTIONS_SHOW_ROUTE,
  LISTINGS,
} from "../../../routes"
import {
  API_COLLECTION_TOGGLE_TOP_COLLECTIONS_ROUTE,
  API_LISTING_COLLECTIONS_ROUTE,
} from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyErrors } from "../../../services/Validation"

const Index = () => {
  const [collections, setCollections] = useState([])
  const { makeRequest, makeAction } = useMakeRequest()
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_LISTING_COLLECTIONS_ROUTE,
      onSuccess: onFetchSuccess,
      onError: onFetchError,
    })
  }

  function onFetchSuccess(response) {
    if (response.status === 200) {
      setCollections(response.data.collections)
    }
  }

  function onFetchError(error) {
    notifyErrors(error)
  }
  function toggleTopHomePage(e, cId) {
    e.preventDefault()
    makeAction({
      method: "post",
      route: API_COLLECTION_TOGGLE_TOP_COLLECTIONS_ROUTE.replace(":id", cId),

      onSuccess: fetchData,
    })
  }
  function redirectTo(e, url) {
    e.preventDefault()
    navigate({ pathname: url })
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <Card.Title>Collections</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Nom</th>
                    <th>Slug</th>
                    <th>Description</th>
                    <th>Produits</th>
                    <th>Page d'accueil</th>
                  </tr>
                </thead>
                <tbody>
                  {collections.map((collection) => (
                    <tr
                      key={collection.id}
                      onClick={(e) =>
                        redirectTo(
                          e,
                          COLLECTIONS_SHOW_ROUTE.replace(":id", collection.id)
                        )
                      }
                    >
                      <td>
                        {collection.thumb && (
                          <img
                            src={collection.thumb}
                            alt={collection.name}
                            width="50px"
                            className="img-thumbnail"
                          />
                        )}
                      </td>
                      <td>{collection.name}</td>
                      <td>{collection.slug}</td>
                      <td>{collection.caption}</td>
                      <td>{collection.listings_count}</td>
                      <td>
                        <Switch
                          checked={collection.attached_to_home}
                          value={collection.attached_to_home}
                          id={"active-" + collection.id}
                          onClick={(e) => {
                            toggleTopHomePage(e, collection.id)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Creator onSuccessCallback={fetchData} />
        </Col>
      </Row>
    </div>
  )
}

export default Index

const breadcrumb = [
  {
    link: LISTINGS,
    caption: "Produits",
  },
  {
    link: COLLECTIONS_ROUTE,
    caption: "Collections",
    active: true,
  },
]
