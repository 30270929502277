/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/variations/CreateModal.js
Created On : 17 May 2022 11:21:15
*/
import React, { useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { Button, Select } from "../../../utilities"
import VariationConfig from "./VariationConfig"

const CreateModal = ({
  setModalVisible,
  visible,
  types,
  setListingVariations,
}) => {
  const [variations, setVariations] = useState([])

  function AddVariation(id) {
    let type = types.find((item) => item.value === parseInt(id))
    if (typeof type !== "undefined") {
      let vs = [...variations]
      type.variants = []
      type.price_varies = false
      type.quantity_varies = false
      vs.push(type)
      setVariations(vs)
    }
  }
  function canBeAdded(option) {
    return !Boolean(variations.find((item) => item.id === option.id))
  }

  const AddVariationOption = () => {
    return (
      <Row>
        <Col md={8}>
          <Select
            label="Choisir une variation"
            options={types.filter(canBeAdded)}
            onChange={(e) => AddVariation(e)}
          />
        </Col>
      </Row>
    )
  }

  function removeVariation(index) {
    let vrs = [...variations]
    vrs.splice(index, 1)
    setVariations(vrs)
  }

  function onSetListingVariations() {
    if (setListingVariations) {
      let vrs = [...variations]
      setListingVariations(vrs)
    }
    setModalVisible(false)
  }
  /**
   *
   * @param {*} variant
   * @param {*} variation
   * @param {*} index index on variations global array
   */
  function setVariants(index, variation, variants) {
    //get variations
    let vrs = [...variations]
    vrs[index].variants = variants
    setVariations(vrs)
  }

  function updateVariation(key, data) {
    let vrs = [...variations]
    vrs[key] = data
    setVariations(vrs)
  }

  return (
    <div>
      <Modal size="lg" show={visible} onHide={() => setModalVisible(false)}>
        <Modal.Header>
          <Modal.Title className="mb-3">Ajouter les variations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {variations.map((variation, key) => (
            <VariationConfig
              setVariants={(variants) => setVariants(key, variation, variants)}
              removeVariation={() => removeVariation(key)}
              updateVariation={(data) => updateVariation(key, data)}
              variation={variation}
              index={key}
              key={key}
            />
          ))}
          {variations.length < 2 ? <AddVariationOption /> : <></>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalVisible(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={() => onSetListingVariations()}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CreateModal
