/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/index.js
Created On : 12 May 2022 14:00:31

all app routes
*/

export const DASHBOARD = "/"
export const STATS = "/stats"
export const STATS_SEARCH_TERMS = STATS + "/searchterms"
//auth
export const LOGIN_ROUTE = "/login"

//orders
const ORDERS = "/orders"
export const ORDERS_MANAGE_ROUTE = ORDERS + "/manage"
export const ORDERS_SHOW_ROUTE = ORDERS_MANAGE_ROUTE + "/show/:id"

//listings
const LISTINGS_BASEURL = "/listings"
export const LISTINGS = LISTINGS_BASEURL + "/manage"
export const ADD_LISTING = LISTINGS_BASEURL + "/add"
export const LISTING_SHOW = LISTINGS + "/show/:id"
export const LISTING_EDIT = LISTINGS + "/edit/:id"
export const DELETE_LISTING = LISTINGS + "/delete/:id"
//* LISTING VARIATIONS TYPES
export const LISTINGS_VARIATIONS_TYPES = "/listings-variations-types"
export const LISTINGS_VARIATIONS_TYPE_SHOW =
  "/listings-variations-types/show/:id"
//* LISNTTING CATEGORIES

export const CATEGORIES_ROUTE = LISTINGS_BASEURL + "/categories"
export const CATEGORY_SHOW_ROUTE = LISTINGS_BASEURL + "/categories/show/:id"

export const BRANDS_ROUTE = LISTINGS_BASEURL + "/brands"

export const COLLECTIONS_ROUTE = LISTINGS_BASEURL + "/collections"
export const COLLECTIONS_SHOW_ROUTE = LISTINGS_BASEURL + "/collections/show/:id"

//finances
const FINANCES = "/finances"
export const FINANCES_CURRENCIES = FINANCES + "/currencies"
export const ADD_CURRENCY = FINANCES_CURRENCIES + "/add"
export const SHOW_CURRENCY = FINANCES_CURRENCIES + "/show/:id"
export const EDIT_CURRENCY = FINANCES_CURRENCIES + "/edit/:id"
//** PAYMENT METHODS */
export const FINCANCES_PAYMENT_METHODS_ROUTE = FINANCES + "/payment-methods"
export const FINCANCES_ADD_PAYMENT_METHODS_ROUTE =
  FINCANCES_PAYMENT_METHODS_ROUTE + "/add"

//settings
const SETTINGS = "/settings"
//** countries and regions */
export const COUNTIRES_REGIONS = SETTINGS + "/countries-regions"
export const COUNTIRES_REGIONS_SHOW = SETTINGS + "/countries-regions/show/:id"
// shippings
export const SHIPPING_PROFILES = SETTINGS + "/shipping-profiles"
