/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/ui/NavOptions.js
Created On : 13 May 2022 19:55:00
*/

import React from "react"

const NavOptions = ({ children }) => {
  return <div className="nav-options--01">{children}</div>
}

export default NavOptions
