/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/errors/404.js
Created On : 12 May 2022 15:01:53
*/

import React from "react"

const Error404 = () => {
  return (
    <div className=" text-center">
      <h3 className="my-3">404</h3>
      <p>Ooops! Page non disponibl!e</p>
    </div>
  )
}

export default Error404
