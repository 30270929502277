/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/categories/index.js
Created On : 25 June 2022 13:58:06
*/

import React, { useState, useEffect } from "react"
import { Card, Col, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Creator from "../../components/listings/brands/Creator"
import { BreadCrumb, Switch } from "../../components/utilities"
import { BRANDS_ROUTE, LISTINGS } from "../../routes"
import { API_BRANDS } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notifyErrors } from "../../services/Validation"

const Index = () => {
  const [brands, setBrands] = useState([])
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_BRANDS,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setBrands(response.data.brands)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <Card.Title>Brands</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>produits</th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map((brand) => (
                    <tr key={brand.id}>
                      <td>
                        <div>{brand.name}</div>
                      </td>
                      <td>{brand.listings_count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>
              <Card.Title>Ajouter une marque</Card.Title>
            </Card.Header>
            <Card.Body>
              <Creator onSuccessCallback={fetchData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Index

const breadcrumb = [
  {
    link: LISTINGS,
    caption: "Produits",
  },
  {
    link: BRANDS_ROUTE,
    caption: "Brands",
    active: true,
  },
]
