/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/form/Switch.js
Created On : 14 May 2022 11:44:16
*/

import React from "react"

const Switch = ({
  type = "checkbox",
  label,
  id,
  value = false,
  onChange,
  error,
  disabled,
  name,
  checked,
  onClick = () => {},
}) => {
  const setOnChange = (e) => {
    if (onChange) {
      if (type === "radio") {
        onChange(e.target.value)
        return
      }
      onChange(e.target.checked)
    }

    return
  }
  const setOnClick = (e) => {
    e.stopPropagation()
    if (!disabled) {
      onClick(e)
    }

    return
  }

  return (
    <>
      <div className="switch--wrapper">
        <label className="switch">
          <input
            type="checkbox"
            name={name}
            id={id}
            label={label}
            value={value}
            onChange={setOnChange}
            disabled={disabled}
            checked={checked}
          />

          <span
            onClick={setOnClick}
            className={`slider ${error ? "is-invalid" : ""}`}
          ></span>
        </label>
        {label && (
          <label className="switch-caption" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    </>
  )
}

export default Switch
