/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/context/AppContext.js
Created On : 12 May 2022 16:52:52
*/

import React, { useState, createContext } from "react"
import { useSelector } from "react-redux"

export const AppContext = createContext()

export const AppContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useSelector((state) => state.auth)

  return (
    <AppContext.Provider value={{ isLoading, setIsLoading, user }}>
      {children}
    </AppContext.Provider>
  )
}
