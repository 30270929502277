/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/show/Inventory.js
Created On : 17 May 2022 10:22:35
*/

import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { FormGroup } from "../../utilities"

const Inventory = ({ data }) => {
  return (
    <div>
      {data ? (
        <>
          <Row>
            <Col md={8}>
              <Card>
                <Card.Body>
                  <div>
                    <Row>
                      <Col md={4}>
                        <label>Prix</label>
                      </Col>
                      <Col md={8}>
                        <FormGroup value={data.f_price} readOnly={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label>Quantité</label>
                      </Col>
                      <Col md={8}>
                        <FormGroup value={data.quantity} readOnly={true} />
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Inventory
