/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/variations-types/variants/Create.js
Created On : 17 May 2022 15:34:04
*/

import React, { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { STORE_LISTINGS_VARIATIONS_TYPE_VARIANT } from "../../../../routes/api"
import { useMakeRequest } from "../../../../services/httpClient"
import { notify } from "../../../../services/Notification"
import { setValidationErrors } from "../../../../services/Validation"
import { Button, FormGroup } from "../../../utilities"

const Create = ({ show, onHide, title = "", id, onStoreSuccess }) => {
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: STORE_LISTINGS_VARIATIONS_TYPE_VARIANT,
      data: {
        type_id: id,
        name: name,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      onStoreSuccess()
      onHide()
    }
  }

  function onError(error) {
    setValidationErrors(setErrors, error)
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form method="put" onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            label="Variant"
            value={name}
            onChange={setName}
            disabled={isLoading}
            error={errors.name}
            required={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            role="button"
            onClick={() => onHide()}
            variant="light"
            disabled={isLoading}
          >
            Annler
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default Create
