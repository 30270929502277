/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/settings/countries/Show.js
Created On : 06 June 2022 18:03:46
*/

import React, { useEffect, useState } from "react"
import { Card, Col, Row, Table } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { BreadCrumb, Button, ButtonList } from "../../../components/utilities"
import { COUNTIRES_REGIONS, COUNTIRES_REGIONS_SHOW } from "../../../routes"
import { API_SHOW_COUNTRY } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyErrors } from "../../../services/Validation"
import ProvinceCreator from "../../../components/settings/countries/provinces/Creator"
import CityCreator from "../../../components/settings/countries/provinces/cities/Creator"
import TownCreator from "../../../components/settings/countries/provinces/cities/towns/Creator"
import DistrictCreator from "../../../components/settings/countries/provinces/cities/towns/districts/Creator"

const Show = () => {
  const { id } = useParams()
  const [country, setCountry] = useState(null)
  const [provinces, setProvinces] = useState([])
  const [addProvince, setAddProvince] = useState(false)
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedTown, setSelectedTown] = useState(null)
  const [addCity, setAddCity] = useState(false)
  const [addTown, setAddTown] = useState(false)
  const [addDistrict, setAddDistrict] = useState(false)
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_SHOW_COUNTRY.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setCountry(response.data.country)
      setProvinces(response.data.provinces)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <h1 className="h4 mb-3">{country?.name}</h1>
      <div className="my-2">
        <ButtonList>
          <Button variant="light" onClick={() => fetchData()}>
            <i className="fa-solid fa-refresh"></i> Actualiser{" "}
          </Button>
        </ButtonList>
      </div>
      <Row>
        <Col md={3}>
          <Card>
            <Card.Header>
              <div className="flex-space-b">
                <Card.Title>Provinces / Régions</Card.Title>
                <ButtonList>
                  <Button
                    variant="secondary"
                    onClick={() => setAddProvince(true)}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </Button>
                </ButtonList>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Villes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {provinces.map((p) => (
                      <tr key={p.id}>
                        <td>{p.id}</td>
                        <td>{p.name}</td>
                        <td>{p.cities_count}</td>
                        <td>
                          <Button
                            variant="info"
                            size="sm"
                            disabled={
                              selectedProvince
                                ? selectedProvince.id === p.id
                                : false
                            }
                            onClick={() => setSelectedProvince(p)}
                          >
                            Selectionner
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <ProvinceCreator
                show={addProvince}
                country={country}
                onSuccessCallback={fetchData}
                onHide={() => setAddProvince(false)}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Header>
              <div className="flex-space-b">
                <Card.Title>
                  Villes{" "}
                  {selectedProvince ? " du " + selectedProvince.name : ""}
                </Card.Title>
                {selectedProvince && (
                  <ButtonList>
                    <Button
                      size="sm"
                      variant="dark"
                      onClick={() => setAddCity(true)}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </Button>
                  </ButtonList>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              {selectedProvince ? (
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Commnunes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProvince.cities.map((c) => (
                        <tr key={c.id}>
                          <td>{c.id}</td>
                          <td>{c.name}</td>
                          <td>{c.towns_count}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              disabled={
                                selectedCity ? selectedCity.id === c.id : false
                              }
                              onClick={() => setSelectedCity(c)}
                            >
                              Selectionner
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <h3 className="my-5 text-center">
                  Veuillez chosir une province
                </h3>
              )}
              {selectedProvince && (
                <CityCreator
                  show={addCity}
                  province={selectedProvince}
                  onSuccessCallback={fetchData}
                  onHide={() => {
                    setAddCity(false)
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
        {selectedCity && (
          <Col md={3}>
            <Card>
              <Card.Header>
                <div className="flex-space-b">
                  <Card.Title>
                    Communes{" "}
                    {selectedProvince
                      ? " de la ville de " + selectedCity.name
                      : ""}
                  </Card.Title>
                  {selectedCity && (
                    <ButtonList>
                      <Button
                        size="sm"
                        variant="dark"
                        onClick={() => setAddTown(true)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </Button>
                    </ButtonList>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                {selectedCity ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Quartiers</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedCity.towns.map((t) => (
                          <tr key={t.id}>
                            <td>{t.id}</td>
                            <td>{t.name}</td>
                            <td>{t.districts_count}</td>
                            <td>
                              <Button
                                variant="info"
                                size="sm"
                                disabled={
                                  selectedTown
                                    ? selectedTown.id === t.id
                                    : false
                                }
                                onClick={() => setSelectedTown(t)}
                              >
                                Selectionner
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <h3 className="my-5 text-center">
                    Veuillez chosir une ville
                  </h3>
                )}
                {selectedCity && (
                  <TownCreator
                    show={addTown}
                    city={selectedCity}
                    onSuccessCallback={fetchData}
                    onHide={() => {
                      setAddTown(false)
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
        {selectedTown && (
          <Col md={3}>
            <Card>
              <Card.Header>
                <div className="flex-space-b">
                  <Card.Title>
                    Quartiers{" "}
                    {selectedTown
                      ? " de la commune de " + selectedTown.name
                      : ""}
                  </Card.Title>
                  {selectedTown && (
                    <ButtonList>
                      <Button
                        size="sm"
                        variant="dark"
                        onClick={() => setAddDistrict(true)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </Button>
                    </ButtonList>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                {selectedTown ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedTown.districts.map((d) => (
                          <tr key={d.id}>
                            <td>{d.id}</td>
                            <td>{d.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <h3 className="my-5 text-center">
                    Veuillez chosir une commune
                  </h3>
                )}
                {selectedTown && (
                  <DistrictCreator
                    show={addDistrict}
                    town={selectedTown}
                    onHide={() => {
                      setAddDistrict(false)
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Show
const breadcrumb = [
  {
    caption: "Paramètres",
    active: true,
  },
  {
    caption: "Pays et Régions",
    link: COUNTIRES_REGIONS,
  },
  {
    caption: "Détails",
    link: COUNTIRES_REGIONS_SHOW,
    active: true,
  },
]
