import React from "react"
import { Form, InputGroup } from "react-bootstrap"

const Input = React.memo(
  ({
    name,
    label,
    type = "text",
    placeholder = "",
    value = "",
    onChange,
    disabled = false,
    error = null,
    isSelect = false,
    options = [],
    text,
    as,
    leftPrepend,
    rightPrepend,
    accept,
    multiple = false,
    required,
    optional,
    plaintext,
    readOnly,
    selected,
    className = "",
    inputClassName = "",
    inputId,
    min,
    max,
    size,
  }) => {
    const setOnChange = (e) => {
      if (type !== "file") {
        onChange(e.target.value)
        return
      }
      // let reader = new FileReader()
      // reader.onload = () => {
      //   let url = reader.result
      //   onChange(url)
      // }
      // reader.readAsDataURL(e.target.files[0])

      if (multiple) {
        let files = Array.from(e.target.files)
        let images = []
        files.forEach((file) => {
          let reader = new FileReader()
          reader.onload = () => {
            let url = reader.result
            images.push(url)
          }
          reader.readAsDataURL(file)
        })
        if (onChange) {
          onChange(images)
        }
      } else {
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0]
          let reader = new FileReader()
          reader.onload = () => {
            if (onChange) {
              onChange({ url: reader.result })
            }
          }
          reader.readAsDataURL(file)
        }
      }
    }

    return (
      <Form.Group className={"form-group " + className}>
        {label && (
          <Form.Label
            className={`${required && "required"} ${optional && "optional"} `}
          >
            {label}
          </Form.Label>
        )}

        <InputGroup>
          {leftPrepend && leftPrepend}
          <Form.Control
            as={as}
            name={name}
            value={value}
            onChange={(e) => setOnChange(e)}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            isInvalid={error}
            accept={accept}
            multiple={multiple}
            plaintext={plaintext}
            readOnly={readOnly}
            required={required}
            id={inputId}
            className={inputClassName}
            min={min}
            max={max}
            size={size}
          />
          {rightPrepend && rightPrepend}
        </InputGroup>

        {text && <Form.Text muted>{text}</Form.Text>}
        {error && (
          <div>
            <Form.Text className="text-danger">{error}</Form.Text>
          </div>
        )}
      </Form.Group>
    )
  }
)

export default Input
