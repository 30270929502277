/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/Manager.js
Created On : 12 May 2022 17:49:22
*/

import React, { useLayoutEffect, useState } from "react"
import { Card, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { BreadCrumb, Pagination } from "../../components/utilities"
import { useSearchParams } from "../../hooks/Url"
import { LISTING_SHOW } from "../../routes"
import { LISTINGS } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notifyError } from "../../services/Validation"

const Manager = () => {
  const [data, setData] = useState({ data: [] })
  const urlParams = useSearchParams()
  const { makeRequest } = useMakeRequest()

  useLayoutEffect(() => {
    fetchData()
  }, [])

  function fetchData(page = 1) {
    makeRequest({
      route: LISTINGS + "?page=" + page,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setData(response.data)
    }
  }

  function onError(error) {
    notifyError(error)
  }

  function onPageChange(page) {
    fetchData(page)
    urlParams.setValue("page", page)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Nom du produit</th>
                <th>Dates</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((listing) => (
                <tr key={listing.id}>
                  <td>
                    <Link to={LISTING_SHOW.replace(":id", listing.id)}>
                      <img
                        src={listing.thumb}
                        alt={listing.title}
                        style={{ width: "50px" }}
                        className="img-thumbnail"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={LISTING_SHOW.replace(":id", listing.id)}>
                      {listing.title}
                    </Link>
                  </td>
                  <td>
                    <div className="small mb-2">{listing.f_created_at}</div>
                    <div className="text-muted small">
                      {listing.f_updated_at}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Pagination data={data} onPageChange={onPageChange} />
    </div>
  )
}

export default Manager

const breadcrumb = [
  {
    link: LISTINGS,
    caption: "Produits",
    active: true,
  },
]
