/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Stats.js
Created On : 20 July 2022 14:56:21
*/

import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { FormGroup } from "../../utilities"

const Stats = ({
  recommendation,
  setRecommandation,
  initialReviewScore,
  setInitialReviewScore,
  initialReviewCount,
  setInitialReviewCount,
  errors = [],
}) => {
  return (
    <Card>
      <Card.Header>Statistiques</Card.Header>
      <Card.Body>
        <Row>
          <Col md={4}>
            <FormGroup
              name="recommentation"
              label="Recommendation "
              type="number"
              value={recommendation}
              onChange={setRecommandation}
              error={errors.recommendation}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6} className="mb-3">
            <FormGroup
              max={5}
              min={0}
              name="reviews"
              label="Note initiale / 5"
              type="text"
              value={initialReviewScore}
              onChange={setInitialReviewScore}
              error={errors.initial_reviews_score}
            />
          </Col>

          <Col md={6} className="mb-3">
            <FormGroup
              name="reviewers"
              label="Commentateurs initial"
              type="text"
              value={initialReviewCount}
              onChange={setInitialReviewCount}
              error={errors.initial_reviews_count}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Stats
