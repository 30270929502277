/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/dashboard/SearchTerms.js
Created On : 01 Août 2022 14:41:44
*/

import React, { useEffect, useState } from "react"
import { Card, Table } from "react-bootstrap"
import { Button, ButtonList } from "../../components/utilities"
import { useSearchParams } from "../../hooks/Url"
import { API_DASHBOARD_FETCH_SEARCH_TERMS } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notifyErrors } from "../../services/Validation"

const SearchTerms = () => {
  const { makeRequest } = useMakeRequest()
  const [terms, setTerms] = useState({ data: [] })
  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_DASHBOARD_FETCH_SEARCH_TERMS,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setTerms(response.data.terms)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <div className="mb-2">
        <ButtonList>
          <Button variant="light" onClick={() => fetchData()}>
            {" "}
            <i className="fa-solid fa-refresh"></i> Actualiser{" "}
          </Button>
        </ButtonList>
      </div>

      <Card>
        <Card.Header></Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Terme</th>
                <th>Visites</th>
              </tr>
            </thead>
            <tbody>
              {terms.data.map((term) => (
                <tr>
                  <td>{term.caption}</td>
                  <td>{term.times}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  )
}

export default SearchTerms
