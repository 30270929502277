/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/orders/MarkAsTreated.js
Created On : 28 Juillet 2022 13:09:24
*/

import React, { useState } from "react"
import { Alert, Modal } from "react-bootstrap"
import { API_ORDER_MARK_AS_TREATED_ROUTE } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notify } from "../../services/Notification"
import { notifyErrors } from "../../services/Validation"
import { Button } from "../utilities"

const Pay = ({ order, onActionSuccess }) => {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  function confirmOrder() {
    makeRequest({
      method: "post",
      route: API_ORDER_MARK_AS_TREATED_ROUTE.replace(":id", order?.id),
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      notify({ message: response.data.message })
      onActionSuccess()
      setShow(false)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <Button
        variant="info"
        className="w-100"
        onClick={() => {
          setShow(true)
        }}
      >
        Marquer comme traité
      </Button>
      {show && (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header className="bg-info ">
            <Modal.Title className="text-white">
              Marquer comme traité
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="info">
              Marquer la commande N<sup>0</sup> {order.id} comme traitée
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="light"
              onClick={() => {
                setShow(false)
              }}
            >
              Annuler
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              variant="info"
              onClick={(e) => {
                confirmOrder(e)
              }}
            >
              Marquer comme traité
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default Pay
