/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/layouts/Master.js
Created On : 12 May 2022 14:10:02
*/
import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { IsLoading } from "../components/utilities"
import { AppContext } from "../context/AppContext"
import { useSearchParams } from "../hooks/Url"
import { useLogout } from "../services/Auth"
import SideMenus from "./SideMenus"

const Master = ({ children }) => {
  const { isLoading } = useContext(AppContext)
  const { pathname } = useLocation()
  const urlParams = useSearchParams()
  const searchParams = urlParams.getSearchParamsToString()
  const logout = useLogout()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, searchParams])
  return (
    <>
      <div id="master--layout">
        <div className="ml--m-menus--wrapper">
          <SideMenus />
        </div>
        <main className="ml--m-content--wrapper">
          <div className="m--top--bar">
            <div className="flex-end">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  logout()
                }}
              >
                <i className="fa-solid fa-right-from-bracket"></i> Se
                déconnecter{" "}
              </a>
            </div>
          </div>
          <IsLoading visible={isLoading} />
          <div className="my-2">{children}</div>
        </main>
      </div>
    </>
  )
}

export default Master
