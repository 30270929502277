/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/variations/types.js
Created On : 17 May 2022 12:15:26
*/

import React, { useState, useEffect } from "react"
import { BreadCrumb, Button, ButtonList } from "../../../components/utilities"
import {
  LISTINGS,
  LISTINGS_VARIATIONS_TYPES,
  LISTINGS_VARIATIONS_TYPE_SHOW,
} from "../../../routes"
import { LISTINGS_VARIATIONS_TYPES as LISTINGS_VARIATIONS_TYPES_API } from "../../../routes/api"
import Creator from "../../../components/listings/variations-types/Create"
import { Card, Col, Row, Table } from "react-bootstrap"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyError } from "../../../services/Validation"
import { useNavigate } from "react-router-dom"

const Index = () => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [data, setData] = useState([])

  const { makeRequest } = useMakeRequest()
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: LISTINGS_VARIATIONS_TYPES_API,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setData(response.data.types)
    }
  }

  function onError(error) {
    notifyError(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <ButtonList>
        <Button variant="primary" onClick={() => setShowCreateModal(true)}>
          <i className="fa-solid fa-plus"></i> Créer
        </Button>
      </ButtonList>
      <h1 className="s_title__01 mb-3">Types de variations des produits</h1>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Variantes</th>
                    <th>Dernière mise à jour</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((type) => (
                    <tr
                      key={type.id}
                      onClick={(e) =>
                        navigate({
                          pathname: LISTINGS_VARIATIONS_TYPE_SHOW.replace(
                            ":id",
                            type.id
                          ),
                        })
                      }
                    >
                      <td>{type.type}</td>
                      <td>{type.variants_count}</td>
                      <td>{type.f_updated_at}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* creator */}
      {showCreateModal ? (
        <Creator
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default Index

const breadcrumb = [
  {
    caption: "Produits",
    link: LISTINGS,
  },
  {
    caption: "Types de variations",
    link: LISTINGS_VARIATIONS_TYPES,
    active: true,
  },
]
