/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/ui/ustomBadge.js
Created On : 13 Mars 2022 18:40:13
*/

import React from "react"

const CustomBadge = ({ value, onClose }) => {
  return (
    <div className="bagde--01">
      <span>{value}</span>
      {onClose && (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault()
            onClose()
          }}
        >
          <i className="fa-solid fa-times"></i>
        </a>
      )}
    </div>
  )
}

export default CustomBadge
