/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/forms/InputWithDropDown.js
Created On : 13 Mars 2022 15:52:17
*/

import React, { useState } from "react"
import { Dropdown, Form } from "react-bootstrap"
import { ClosableBadge } from ".."
import { useMakeRequest } from "../../../services/httpClient"

const InputWithDropDown = ({
  route,
  method,
  setIsLoading,
  name,
  label,
  type = "text",
  placeholder = "",
  error = null,
  disabled = false,
  onChange,
  selectedValue,
  autoComplete = "off",
  autofill = "off",
  text,
}) => {
  const { makeRequest } = useMakeRequest()
  const [listVisible, setListVisible] = useState(false)
  const [list, setList] = useState([])
  function fetchData(query) {
    makeRequest({
      method: method,
      route: route.replace(":query", query),
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onSuccess,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setList(response.data)
      setListVisible(true)
    }
  }

  function onError(error) {}

  function setOnChange(e) {
    e.preventDefault()
    let value = e.target.value
    value = value.trim()
    if (value.length >= 2) {
      fetchData(value)
    }
  }

  function setOnSelect(item) {
    if (onChange) {
      onChange(item)
    }
  }

  function removeValue() {
    onChange(null)
  }

  return (
    <div>
      <Dropdown
        show={list.length > 0 && listVisible}
        onToggle={(e) => setListVisible(e)}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          isInvalid={error}
          autoComplete={autoComplete}
          autofill={autofill}
          onChange={(e) => setOnChange(e)}
        />
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
        {text && <Form.Text muted>{text}</Form.Text>}
        {selectedValue && (
          <ClosableBadge value={selectedValue} onClose={removeValue} />
        )}

        <Dropdown.Menu>
          {list.map((item, key) => (
            <Dropdown.Item key={key} onClick={() => setOnSelect(item)}>
              <span className="caption">{item.caption}</span>
              {item.subcaption && (
                <div className="subcaption">{item.subcaption}</div>
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default InputWithDropDown
