/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/variations/Creator.js
Created On : 17 May 2022 11:17:34
*/

import React, { useState } from "react"
import { Button } from "../../../utilities"
import CreateModal from "./CreateModal"

const Creator = ({ types, setVariations, error }) => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <div>
      <Button variant="primary" onClick={() => setModalVisible(true)}>
        Ajouter les variations
      </Button>
      <CreateModal
        types={types}
        setListingVariations={setVariations}
        visible={modalVisible}
        setModalVisible={setModalVisible}
      />
      {error ? (
        <div className="my-2">
          <span className="text-danger">{error}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Creator
