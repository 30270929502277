/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/services/httpClient.js
Created On : 12 May 2022 16:49:57
*/

import { useContext } from "react"
import { AppContext } from "../context/AppContext"
import axios from "axios"
import { FORBIDDEN, NETWORK_ERROR, UNAUTHORIZED } from "../constants/httpCodes"
import { notify } from "./Notification"
import { notifyError } from "./Validation"
import { useLogin } from "./Auth"

export function useMakeRequest() {
  const { setIsLoading } = useContext(AppContext)
  const login = useLogin()
  function makeRequest({
    method = "get",
    route,
    data = [],
    setLoader,
    onSuccess,
    onError,
    successCallback, //for intermediate helper with preset action before call the on success
    errorCallback, //for intermediate helper with preset action before call the on success
  }) {
    setLoader = setLoader ? setLoader : setIsLoading
    axios.defaults.withCredentials = true
    try {
      // loading
      manageLoader(setLoader, true)
      axios[method](route, data)
        .then((response) => {
          /**  request success */
          console.log(response)
          //set response
          if (onSuccess) {
            onSuccess(response, successCallback)
          }
          //loader
          manageLoader(setLoader, false)
        })
        .catch((error) => {
          /**  request error */
          //loader
          manageLoader(setLoader, false)
          console.log("error", error)
          //
          if (error.response.status === UNAUTHORIZED) {
            notify({ type: "error", message: error.response.data.message })
            login({ user: null })
            return
          }
          if (error.code === NETWORK_ERROR) {
            notify({ type: "error", message: "Erreur réseau" })
            return
          }
          if (error.response.status === FORBIDDEN) {
            notify({ type: "error", message: error.response.data.message })
            login({ user: null })
            return
          }
          //
          if (onError) {
            onError(error, errorCallback)
          }
        })
    } catch (error) {
      /**  request failed */
      console.log(error)
    }
  }

  function makeAction({
    method,
    route,
    onSuccess = null,
    onError = null,
    data = [],
  }) {
    makeRequest({
      method: method,
      route: route,
      data: data,
      onSuccess: onActionSuccess,
      onError: onActionError,
      successCallback: onSuccess,
      errorCallback: onError,
    })
  }

  function onActionSuccess(response, callback) {
    notify({
      message: response.data.message
        ? response.data.message
        : "Action effectuée avec succès",
    })
    if (callback) {
      callback()
    }
  }
  function onActionError(error, callback) {
    notifyError(error)
    if (callback) {
      callback()
    }
  }

  return { makeRequest, makeAction }
}

/**
 *
 *
 * @param {*} manager
 * @param {*} value
 */
function manageLoader(manager, value) {
  if (manager) {
    manager(value)
  }
}
