/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/orders/Cancel.js
Created On : 09 June 2022 11:21:04
*/
import React, { useState } from "react"
import { Alert, Modal } from "react-bootstrap"
import { API_CANCEL_ORDER_ROUTE } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notify } from "../../services/Notification"
import { notifyErrors } from "../../services/Validation"
import { Button, FormGroup } from "../utilities"

const Cancel = ({ order, onActionSuccess }) => {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [cancelExplanation, setCancelExplanation] = useState("")
  const { makeRequest } = useMakeRequest()

  function cancelOrder() {
    makeRequest({
      method: "post",
      route: API_CANCEL_ORDER_ROUTE.replace(":id", order?.id),
      data: {
        explanation: cancelExplanation,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      notify({ message: response.data.message })
      onActionSuccess()
      setShow(false)
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <div>
      <Button
        variant="danger"
        className="w-100"
        onClick={() => {
          setShow(true)
        }}
      >
        Annuler
      </Button>
      {show && (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header>
            <Modal.Title>
              Annuler une commande N<sup>0</sup> {order.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger">
              Après annulation , le remboursement de la commande devra être fait
              manuellement
            </Alert>
            <FormGroup
              value={cancelExplanation}
              onChange={setCancelExplanation}
              error={errors.explanation}
              disabled={isLoading}
              as="textarea"
              label="Message au client"
              optional={true}
              inputClassName="mh-200"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="light"
              onClick={() => {
                setShow(false)
              }}
            >
              Annuler
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              variant="danger"
              onClick={(e) => {
                cancelOrder(e)
              }}
            >
              Annuler la commande
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default Cancel
