/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/categories/Show.js
Created On : 26 June 2022 12:06:43
*/

import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Listings from "../../../components/listings/categories/show/Listings"
import { BreadCrumb } from "../../../components/utilities"
import { CATEGORIES_ROUTE, LISTINGS } from "../../../routes"
import { API_SHOW_CATEGORY_ROUTE } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyErrors } from "../../../services/Validation"

const Show = () => {
  const { id } = useParams()
  const [category, setCategory] = useState(null)
  const [listings, setListings] = useState([])
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_SHOW_CATEGORY_ROUTE.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setCategory(response.data.category)
      setListings(response.data.listings)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      {category && (
        <>
          <h1 className="h3 text-muted mb-2">{category.name}</h1>
          <Tabs defaultActiveKey="products" id="category-show" className="mb-3">
            <Tab eventKey="products" title="Produits">
              <Listings listings={listings} onChange={fetchData} />
            </Tab>
            <Tab eventKey="confirguration" title="Configurations">
              Configurations
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  )
}

export default Show

const breadcrumb = [
  {
    caption: "Produits",
    link: LISTINGS,
  },
  {
    caption: "Catégories",
    link: CATEGORIES_ROUTE,
  },
  {
    caption: "Voir catégorie",
    link: "/",
    active: true,
  },
]
