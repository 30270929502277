/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/categories/index.js
Created On : 25 June 2022 13:58:06
*/

import React, { useState, useEffect } from "react"
import { Card, Col, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Creator from "../../../components/listings/categories/Creator"
import { BreadCrumb, Switch } from "../../../components/utilities"
import {
  CATEGORIES_ROUTE,
  CATEGORY_SHOW_ROUTE,
  LISTINGS,
} from "../../../routes"
import {
  API_CATEGORY_TOGGLE_TOP_CATEGORIES_ROUTE,
  API_LISTING_CATEGORIES_ROUTE,
} from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyErrors } from "../../../services/Validation"

const Index = () => {
  const [categories, setCategories] = useState([])
  const { makeRequest, makeAction } = useMakeRequest()
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_LISTING_CATEGORIES_ROUTE,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setCategories(response.data.categories)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }
  function toggleTopHomePage(e, cId) {
    e.preventDefault()
    makeAction({
      method: "post",
      route: API_CATEGORY_TOGGLE_TOP_CATEGORIES_ROUTE.replace(":id", cId),

      onSuccess: fetchData,
    })
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <Card.Title>Catégories</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Nom</th>
                    <th>produits</th>
                    <th>Slug</th>
                    <th>Top Catégories</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr
                      key={category.id}
                      onClick={() =>
                        navigate(
                          CATEGORY_SHOW_ROUTE.replace(":id", category.id)
                        )
                      }
                    >
                      <td>
                        <img
                          src={category.image}
                          width="50px"
                          alt={category.name}
                        />
                      </td>
                      <td>
                        <div>{category.name}</div>
                        <span className="text-muted small">
                          {category.bread_crumb}
                        </span>
                      </td>
                      <td>{category.listings_count}</td>
                      <td>{category.slug}</td>
                      <td>
                        <Switch
                          checked={category.attached_to_home}
                          value={category.attached_to_home}
                          id={"active-" + category.id}
                          onClick={(e) => {
                            toggleTopHomePage(e, category.id)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>
              <Card.Title>Ajouter une catégorie</Card.Title>
            </Card.Header>
            <Card.Body>
              <Creator onSuccessCallback={fetchData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Index

const breadcrumb = [
  {
    link: LISTINGS,
    caption: "Produits",
  },
  {
    link: CATEGORIES_ROUTE,
    caption: "Catégories",
    active: true,
  },
]
