/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/finances/payement-methods/index.js
Created On : 28 May 2022 19:48:11
*/
import React, { useState, useEffect } from "react"
import { Card, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { BreadCrumb, ButtonList, Switch } from "../../../components/utilities"
import { FINCANCES_ADD_PAYMENT_METHODS_ROUTE } from "../../../routes"
import { PAYMENT_METHODS } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyErrors } from "../../../services/Validation"

const Index = () => {
  const [methods, setMethods] = useState([])
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: PAYMENT_METHODS,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setMethods(response.data.methods)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <ButtonList>
        <Link
          to={FINCANCES_ADD_PAYMENT_METHODS_ROUTE}
          className="btn btn-primary"
        >
          <i className="fa-solid fa-plus"></i> Créer
        </Link>
      </ButtonList>
      <div className="my-3">
        <Card>
          <Card.Body>
            <Table>
              <thead>
                <tr>
                  <th>Méthode</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {methods.map((method) => (
                  <tr key={method.id}>
                    <td>{method.name}</td>
                    <td>
                      <Switch checked={method.is_active} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Index

const breadcrumb = [
  {
    caption: "Finances",
    active: true,
  },
  {
    caption: "Methodes de paiement",
    link: FINCANCES_ADD_PAYMENT_METHODS_ROUTE,
    active: true,
  },
]
