/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/finances/Add.js
Created On : 14 May 2022 10:28:00
*/

import React, { useContext, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import {
  BreadCrumb,
  Button,
  ButtonList,
  Checkbox,
  FormGroup,
  Switch,
} from "../../../components/utilities"
import { AppContext } from "../../../context/AppContext"
import { ADD_CURRENCY, FINANCES_CURRENCIES } from "../../../routes"
import { STORE_CURRENCY } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { setValidationErrors } from "../../../services/Validation"

const Add = () => {
  const [currency, setCurrency] = useState("")
  const [name, setName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [errors, setErrors] = useState([])
  const { isLoading } = useContext(AppContext)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: STORE_CURRENCY,
      data: {
        currency: currency,
        name: name,
        symbol: symbol,
        is_active: isActive,
      },
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      resetData()
    }
  }

  function resetData() {
    setErrors([])
    setCurrency("")
    setName("")
    setSymbol("")
    setIsActive(false)
  }

  function onError(error) {
    setValidationErrors(setErrors, error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Row>
        <Col md={6}>
          <Form method="post" onSubmit={onSubmit}>
            <Card>
              <Card.Header>Ajouter une devise</Card.Header>
              <Card.Body>
                <FormGroup
                  label="Devise"
                  disabled={isLoading}
                  value={currency}
                  onChange={setCurrency}
                  error={errors.currency}
                />

                <Row>
                  <Col md={6}>
                    <FormGroup
                      label="Nom"
                      disabled={isLoading}
                      value={name}
                      onChange={setName}
                      error={errors.name}
                    />
                  </Col>
                  <Col md={6}>
                    <FormGroup
                      label="Symbole"
                      disabled={isLoading}
                      value={symbol}
                      onChange={setSymbol}
                      error={errors.symbol}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Switch
                      label="Activer la devise"
                      id="activate"
                      disabled={isLoading}
                      value={isActive}
                      onChange={setIsActive}
                      error={errors.is_acitve}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <ButtonList>
                  <Link
                    className="btn btn-light"
                    to={FINANCES_CURRENCIES}
                    disabled={isLoading}
                  >
                    Annuler
                  </Link>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Créer
                  </Button>
                </ButtonList>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Add

const breadcrumb = [
  {
    caption: "Finances",
    link: "/",
    active: true,
  },
  {
    caption: "Dévises",
    link: FINANCES_CURRENCIES,
  },
  {
    caption: "Ajouter une dévise",
    link: ADD_CURRENCY,
    active: true,
  },
]
