/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Infos.js
Created On : 12 May 2022 15:39:13
*/
import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { API_SEARCH_BRAND, API_SEARCH_CATEGORY } from "../../../routes/api"
import {
  FormGroup,
  FormGroupWithDropDown,
  Select,
  Switch,
} from "../../utilities"

const Infos = ({
  title = "",
  setTitle,
  description = "",
  setDescription,
  category = "",
  setCategory,
  brand = "",
  setBrand,
  categories = [],
  customizable = false,
  setCustomizable,
  customizationText,
  setCustomizationText,
  customizationMaxLengh,
  setCustomisationMaxLengh,
  supplier,
  setSupplier,
  supplierProductUrl,
  setSupplierProductUrl,
  errors = [],
  isLoading = false,
}) => {
  return (
    <div>
      <Card>
        <Card.Header>Informations</Card.Header>
        <Card.Body>
          <div className="mb-2">
            <FormGroupWithDropDown
              name="category"
              placeholder="Entrez une catégorie"
              label="Catégorie"
              route={API_SEARCH_CATEGORY}
              onChange={setCategory}
              selectedValue={category ? category.subcaption : null}
              error={errors.category}
              required={true}
            />
          </div>

          <Row className="mb-2">
            <Col md={4}>
              <FormGroupWithDropDown
                optional={true}
                name="brand"
                placeholder="Entrez une marque"
                label="Marque"
                route={API_SEARCH_BRAND}
                onChange={setBrand}
                selectedValue={brand ? brand.caption : null}
                error={errors.brand}
              />
            </Col>
          </Row>
          <FormGroup
            label="Titre"
            value={title}
            onChange={setTitle}
            name="produit"
            error={errors.title}
            disabled={isLoading}
            required={true}
          />
          <FormGroup
            as="textarea"
            label="Description"
            value={description}
            onChange={setDescription}
            name="description"
            error={errors.description}
            disabled={isLoading}
            required={true}
            inputClassName="mh-200"
          />
          <Row className="mb-3">
            <Col md={6} className="mb-3">
              <FormGroup
                name="supplier"
                label="Fournisseur"
                type="text"
                value={supplier}
                onChange={setSupplier}
                error={errors.supplier}
              />
            </Col>
            <Col md={6} className="mb-3">
              <FormGroup
                name="supplier-link"
                label="Lien du produit"
                type="text"
                value={supplierProductUrl}
                onChange={setSupplierProductUrl}
                error={errors.supplier_url}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Switch
                label="Personnaliser"
                value={customizable}
                checked={customizable}
                onChange={setCustomizable}
                id="is-customizable"
                error={errors.is_customizable}
              />
            </Col>
            {customizable && (
              <Col md={6}>
                <FormGroup
                  as="textarea"
                  label="Texte de personnalisation"
                  value={customizationText}
                  onChange={setCustomizationText}
                  error={errors.customisation_text}
                />
                <FormGroup
                  label="Nombre de caractère maximum"
                  type="number"
                  value={customizationMaxLengh}
                  onChange={setCustomisationMaxLengh}
                  error={errors.customization_max_lengh}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Infos
