/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/ui/OrderInfosIndicator.js
Created On : 04 April 2022 11:47:22
*/

import React from "react"

const OrderInfosIndicator = ({ item }) => {
  return (
    <div className="alert alert-info">
      {item.variations_types.map((v, key) => (
        <div className="item--price" key={key}>
          <span className="text-muted">{v.type} : </span> {v.value}
        </div>
      ))}

      {item.customization && (
        <div className="item--quantity">
          <span className="text-muted">Personnalisation : </span>{" "}
          {item.customization}
        </div>
      )}
    </div>
  )
}

export default OrderInfosIndicator
