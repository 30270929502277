/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/collections/Show.js
Created On : 27 June 2022 14:52:51
*/

import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Listings from "../../../components/listings/collections/show/Listings"
import { BreadCrumb } from "../../../components/utilities"
import { COLLECTIONS_ROUTE, LISTINGS } from "../../../routes"
import { API_SHOW_COLLECTION_ROUTE } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyErrors } from "../../../services/Validation"

const Show = () => {
  const { id } = useParams()
  const [collection, setCollection] = useState(null)
  const [listings, setListings] = useState([])
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_SHOW_COLLECTION_ROUTE.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setCollection(response.data.collection)
      setListings(response.data.listings)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      {collection && (
        <>
          <h1 className="h3 text-muted mb-2">
            <small>
              <em> Collection :</em>
            </small>{" "}
            {collection.name}
          </h1>
          <Tabs defaultActiveKey="products" id="category-show" className="mb-3">
            <Tab eventKey="products" title="Produits">
              <Listings listings={listings} onChange={fetchData} />
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  )
}

export default Show

const breadcrumb = [
  {
    caption: "Produits",
    link: LISTINGS,
  },
  {
    caption: "Collections",
    link: COLLECTIONS_ROUTE,
  },
  {
    caption: "Voir la collection",
    link: "/",
    active: true,
  },
]
