/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/settings/shippings/createProfile.js
Created On : 25 Juillet 2022 13:22:50
*/

import React, { useState } from "react"
import { Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { API_STORE_SHIPPING_PROFILE } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyErrors } from "../../../services/Validation"
import { Button, ButtonList, FormGroup, Select } from "../../utilities"

const CreateProfile = ({ show, onHide, onReload }) => {
  const [name, setName] = useState("")
  const [minProcessTime, setMinProcessTime] = useState("")
  const [maxProcessTime, setMaxProcessTime] = useState("")
  const [localPrice, setLocalPrice] = useState("")
  const [localAdditionaPrice, setLocalAdditionalPrice] = useState("")
  const [awayPrice, setAwayPrice] = useState("")
  const [additionalAwayPrice, setAdditionalAwayPrice] = useState("")
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { makeRequest } = useMakeRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "post",
      route: API_STORE_SHIPPING_PROFILE,
      data: {
        name: name,
        min_process_time: minProcessTime,
        max_process_time: maxProcessTime,
        local_price: localPrice,
        local_additional_price: localAdditionaPrice,
        away_price: awayPrice,
        away_additional_price: additionalAwayPrice,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      onReload()
      onHide()
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        <Form method="post" onSubmit={onSubmit}>
          <Card>
            <Card.Header>Profil de livraison</Card.Header>
            <Card.Body>
              <FormGroup
                label="Nom du profil "
                value={name}
                onChange={setName}
                error={errors.name}
              />
              <Row>
                <Col md={6}>
                  <FormGroup
                    value={minProcessTime}
                    onChange={setMinProcessTime}
                    error={errors.min_process_time}
                    type="number"
                    min="1"
                    max="10"
                    label="Temps min de traitement"
                    rightPrepend={<InputGroup.Text>Jours</InputGroup.Text>}
                  />
                </Col>
                <Col md={6}>
                  <FormGroup
                    value={maxProcessTime}
                    onChange={setMaxProcessTime}
                    error={errors.max_process_time}
                    type="number"
                    min="1"
                    max="30"
                    label="Temps max de traitement"
                    rightPrepend={<InputGroup.Text>Jours</InputGroup.Text>}
                  />
                </Col>
              </Row>
              <h4 className="h6 text-muted">Livraison locale</h4>
              <Row>
                <Col md={6}>
                  <FormGroup
                    type="Un article"
                    label="Prix"
                    value={localPrice}
                    onChange={setLocalPrice}
                    name="prix"
                    error={errors.local_price}
                    disabled={isLoading}
                    rightPrepend={<InputGroup.Text>$</InputGroup.Text>}
                  />
                </Col>
                <Col md={6}>
                  <FormGroup
                    type="number"
                    label="Article supplementaire"
                    value={localAdditionaPrice}
                    onChange={setLocalAdditionalPrice}
                    name="prix"
                    error={errors.local_additional_price}
                    disabled={isLoading}
                    rightPrepend={<InputGroup.Text>$</InputGroup.Text>}
                  />
                </Col>
              </Row>
              <h4 className="h6 text-muted">Autres province</h4>
              <Row>
                <Col md={6}>
                  <FormGroup
                    type="Un article"
                    label="Prix"
                    value={awayPrice}
                    onChange={setAwayPrice}
                    name="prix"
                    error={errors.away_price}
                    disabled={isLoading}
                    rightPrepend={<InputGroup.Text>$</InputGroup.Text>}
                  />
                </Col>
                <Col md={6}>
                  <FormGroup
                    type="number"
                    label="Article supplementaire"
                    value={additionalAwayPrice}
                    onChange={setAdditionalAwayPrice}
                    name="prix"
                    error={errors.away_additional_price}
                    disabled={isLoading}
                    rightPrepend={<InputGroup.Text>$</InputGroup.Text>}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <ButtonList>
                <Button variant="light" onClick={() => onHide()}>
                  Annuler
                </Button>
                <Button variant="primary" type="submit">
                  Enregistrer
                </Button>
              </ButtonList>
            </Card.Footer>
          </Card>
        </Form>
      </Modal>
    </>
  )
}

export default CreateProfile
