/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/variations/Displayer.js
Created On : 23 May 2022 17:10:18
*/
import React from "react"
import { Badge, Card, InputGroup, Table } from "react-bootstrap"
import { FormGroup } from "../../../utilities"
import ItemLine from "./display/ItemLine"

const Displayer = ({
  errors = [],
  variations = { header: [], body: [] },
  mainCurrency,
  onUpdateVariationsData,
}) => {
  const { header, body } = variations

  function onOldPriceUpdated(value, idx) {
    let cB = body
    cB[idx].old_price = value
    onUpdateVariationsData(cB)
  }

  function onPriceUpdated(value, idx) {
    let cB = body
    cB[idx].price = value
    onUpdateVariationsData(cB)
  }
  function onQuantityUpdated(value, idx) {
    let cB = body
    cB[idx].quantity = value
    onUpdateVariationsData(cB)
  }
  function onRemoveItem(idx) {
    let cB = body
    cB.splice(idx, 1)
    onUpdateVariationsData(cB)
  }

  return (
    <>
      <h1 className="text-muted h5">Variations</h1>
      {header[0] && (
        <Card>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{header[0].caption}</th>
                  {header[1] && <th>{header[1].caption}</th>}
                  <th>Prix</th>
                  <th>Quantité</th>
                  <th>
                    {" "}
                    <span className="text-line-througth">Ancien prix</span>{" "}
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {body.map((variation, idx) => (
                  <ItemLine
                    key={idx}
                    idx={idx}
                    variation={variation}
                    hasSecondVariation={header[1]}
                    mainCurrency={mainCurrency}
                    onPriceUpdated={(value) => onPriceUpdated(value, idx)}
                    onOldPriceUpdated={(value) => onOldPriceUpdated(value, idx)}
                    onQuantityUpdated={(value) => onQuantityUpdated(value, idx)}
                    onRemoveItem={() => onRemoveItem(idx)}
                    errors={errors}
                  />
                ))}
              </tbody>
            </Table>
            {errors.variations && (
              <div>
                <span className="text-danger">{errors.variations}</span>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default Displayer
