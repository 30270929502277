/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/categories/show/Listings.js
Created On : 26 June 2022 12:39:53
*/
import React from "react"
import { Card, Table } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { API_CATEGOEY_TOGGLE_LISTING_ROUTE } from "../../../../routes/api"
import { useMakeRequest } from "../../../../services/httpClient"
import { Switch } from "../../../utilities"

const Listings = ({ listings = [], onChange }) => {
  const { makeAction } = useMakeRequest()
  const { id } = useParams()

  function toggleListing(e, listingId) {
    e.preventDefault()
    makeAction({
      method: "post",
      route: API_CATEGOEY_TOGGLE_LISTING_ROUTE.replace(":id", id),
      data: {
        listing: listingId,
      },
      onSuccess: onChange,
    })
  }

  return (
    <Card>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Nom</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listings.map((listing) => (
              <tr key={listing.id}>
                <td>
                  <img src={listing.thumb} alt={listing.title} width="50px" />
                </td>
                <td>{listing.title}</td>
                <td>
                  <Switch
                    checked={listing.is_attached}
                    value={listing.is_attached}
                    id={"active-" + listing.id}
                    onClick={(e) => {
                      toggleListing(e, listing.id)
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default Listings
