/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/router.js
Created On : 12 May 2022 14:04:15
*/

import React, { useContext } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { LOGIN_ROUTE } from "."
import { AppContext } from "../context/AppContext"
import Login from "../pages/auth/Login"
import Protected from "./Protected"

const Router = () => {
  const { user } = useContext(AppContext)
  return (
    <div>
      <Routes>
        <Route element={<Login />} path={LOGIN_ROUTE} />
        <Route
          element={
            !user ? <Navigate to={LOGIN_ROUTE} replace /> : <Protected />
          }
          path="/*"
        />
      </Routes>
    </div>
  )
}

export default Router
