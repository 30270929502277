/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Form.js
Created On : 12 May 2022 15:33:08
*/

import React, { useContext, useState, useEffect } from "react"
import { Card, Col, Row, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../../../context/AppContext"
import { LISTINGS } from "../../../routes"
import { CREATE_LISTING, STORE_LISTING } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyError, setValidationErrors } from "../../../services/Validation"
import { Button } from "../../utilities"
import Infos from "./Infos"
import Inventory from "./Inventory"
import Photos from "./Photos"
import Referencement from "./Referencement"
import Shipping from "./Shipping"
import Stats from "./Stats"

const CreateForm = () => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [seoTitle, setSeoTitle] = useState("")
  const [seoDescription, setSeoDescription] = useState("")
  const [slug, setSlug] = useState("")
  const [customizable, setCustomizable] = useState(false)
  const [customizationText, setCustomizationText] = useState("")
  const [customizationMaxLengh, setCustomisationMaxLengh] = useState("")
  const [price, setPrice] = useState("")
  const [oldPrice, setOldPrice] = useState("")
  const [quantity, setQuantity] = useState(1)
  const [brand, setBrand] = useState("")
  const [category, setCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [mainCurrency, setMainCurrency] = useState(null)
  const [listingVariations, setListingVariations] = useState({
    header: [],
    body: [],
  })

  const [recommendation, setRecommandation] = useState(5)
  const [initialReviewScore, setInitialReviewScore] = useState("")
  const [initialReviewCount, setInitialReviewCount] = useState("")

  const [supplier, setSupplier] = useState("")
  const [supplierProductUrl, setSupplierProductUrl] = useState("")
  const [shippingProfile, setShippingProfile] = useState("")
  const [shippingProfiles, setShippingProfiles] = useState([])
  const [shippedFrom, setShippedFrom] = useState("")

  const [photos, setPhotos] = useState([])
  const [errors, setErrors] = useState([])
  const [variationTypes, setVariationsType] = useState([])
  const { isLoading } = useContext(AppContext)
  const navigate = useNavigate()
  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: CREATE_LISTING,
      onSuccess: onFetchSuccess,
      onError: onFetchError,
    })
  }

  function onFetchSuccess(response) {
    if (response.status === 200) {
      setMainCurrency(response.data.main_currency)
      setVariationsType(response.data.variation_types)
      setCategories(response.data.categories)
      setShippingProfiles(response.data.shipping_profiles)
    }
  }

  function onFetchError(error) {
    notifyError(error)
  }

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: STORE_LISTING,
      data: {
        title: title,
        description: description,
        seo_title: seoTitle,
        seo_description: seoDescription,
        slug: slug,
        is_customizable: customizable,
        customization_text: customizable ? customizationText : null,
        customization_max_lengh: customizable ? customizationMaxLengh : null,
        price: price,
        old_price: oldPrice,
        quantity: quantity,
        category: category?.value,
        main_currency: mainCurrency?.id,
        shipping_profile: shippingProfile?.id,
        variations:
          listingVariations.body.length > 0 ? listingVariations.body : null,
        photos: photos,
        supplier: supplier,
        supplier_url: supplierProductUrl,
        recommendation: recommendation,
        initial_reviews_score: initialReviewScore,
        initial_reviews_count: initialReviewCount,
        shipped_from: shippedFrom,
        brand: brand?.value,
      },
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      resetData()
    }
  }

  function resetData() {
    setErrors([])
    setTitle("")
    setDescription("")
    setPrice("")
    setQuantity(1)
    setPhotos([])
    setListingVariations({ header: [], body: [] })
  }

  function onError(error) {
    setValidationErrors(setErrors, error)
  }

  function abort() {
    navigate(LISTINGS)
  }

  return (
    <Form method="post" onSubmit={onSubmit}>
      <Row>
        <Col md={8}>
          <div>
            <h1 className="s_title__01 mb-3">Ajouter un produit</h1>
            <div className="mb-3">
              <Photos
                errors={errors}
                images={photos}
                setImages={setPhotos}
                isLoading={isLoading}
              />
            </div>
            <div className="mb-3">
              <Infos
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                category={category}
                setCategory={setCategory}
                brand={brand}
                setBrand={setBrand}
                categories={categories}
                errors={errors}
                isLoading={isLoading}
                customizable={customizable}
                setCustomizable={setCustomizable}
                customizationText={customizationText}
                setCustomizationText={setCustomizationText}
                customizationMaxLengh={customizationMaxLengh}
                setCustomisationMaxLengh={setCustomisationMaxLengh}
                supplier={supplier}
                setSupplier={setSupplier}
                supplierProductUrl={supplierProductUrl}
                setSupplierProductUrl={setSupplierProductUrl}
              />
            </div>
            <div className="mb-3">
              <Inventory
                price={price}
                setPrice={setPrice}
                quantity={quantity}
                setQuantity={setQuantity}
                mainCurrency={mainCurrency}
                errors={errors}
                isLoading={isLoading}
                variationTypes={variationTypes}
                variations={listingVariations}
                setVariations={setListingVariations}
                oldPrice={oldPrice}
                setOldPrice={setOldPrice}
              />
            </div>
            <div className="mb-3">
              <Stats
                recommendation={recommendation}
                setRecommandation={setRecommandation}
                initialReviewScore={initialReviewScore}
                setInitialReviewScore={setInitialReviewScore}
                initialReviewCount={initialReviewCount}
                setInitialReviewCount={setInitialReviewCount}
                errors={errors}
              />
            </div>
            <div className="mb-3">
              <Shipping
                shippingProfile={shippingProfile}
                setShippingProfile={setShippingProfile}
                shippingProfiles={shippingProfiles}
                shippedFrom={shippedFrom}
                setShippedFrom={setShippedFrom}
                errors={errors}
              />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <Card className="mb-2">
            <Card.Header>Référencement</Card.Header>
            <Card.Body>
              <Referencement
                seoTitle={seoTitle}
                setSeoTitle={setSeoTitle}
                seoDescription={seoDescription}
                setSeoDescription={setSeoDescription}
                slug={slug}
                setSlug={setSlug}
                errors={errors}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>Finaliser</Card.Header>
            <Card.Body>
              <div className="flex-space-b">
                <Button
                  variant="light"
                  onClick={abort}
                  role="button"
                  disabled={isLoading}
                >
                  Annuler
                </Button>
                <Button
                  variant="secondary"
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Poster
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}

export default CreateForm
