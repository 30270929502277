/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/orders/manage/Overview.js
Created On : 28 Juillet 2022 13:33:00
*/

import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"

const Overview = ({ data = [] }) => {
  const Displayer = ({ item }) => {
    return (
      <div className={`ad-indicator-overview bgcolorable ${item.bg}`}>
        <div className="value-c">
          <h3 className="id-title">{item.title}</h3>
          <h2 className="id-value">{item.value}</h2>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Row>
        {data.map((item, key) => (
          <Col key={key} md={3}>
            <Displayer item={item} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Overview
