/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/settings/countries/Creator.js
Created On : 06 June 2022 17:17:38
*/
import React, { useContext, useState } from "react"
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { AppContext } from "../../../context/AppContext"
import { API_STORE_COUNTRY } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notify } from "../../../services/Notification"
import { notifyErrors } from "../../../services/Validation"
import { Button, FormGroup } from "../../utilities"
import Input from "../../utilities/form/Input"

const Creator = ({ show, onHide }) => {
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [errors, setErrors] = useState([])
  const { makeRequest } = useMakeRequest()
  const { isLoading } = useContext(AppContext)

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "put",
      route: API_STORE_COUNTRY,
      data: { name: name, code: code },
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      notify({ message: response.data.message })
      setName("")
      setCode("")
      setErrors([])
      onHide()
    }
  }

  function onError(error) {
    notifyErrors(error, setErrors)
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form method="post " onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Ajouter un Pays</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            value={name}
            onChange={setName}
            error={errors.name}
            label="Nom du Pays"
            name="pays"
            required={true}
          />
          <Row>
            <Col md={6}>
              <Input
                label="Code téléphone"
                name="phone-code"
                leftPrepend={<InputGroup.Text>+</InputGroup.Text>}
                type="tel"
                value={code}
                onChange={setCode}
                required={true}
                error={errors.code}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide()}>
            Annuler
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            variant="primary"
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default Creator
