/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/Delete.js
Created On : 13 May 2022 20:50:05
*/
import React, { useContext } from "react"
import { Alert, Card, Col, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { BreadCrumb, Button, ButtonList } from "../../components/utilities"
import { AppContext } from "../../context/AppContext"
import { DELETE_LISTING, LISTINGS, LISTING_SHOW } from "../../routes"
import { LISTING_DELETE } from "../../routes/api"
import { useMakeRequest } from "../../services/httpClient"
import { notify } from "../../services/Notification"
import { notifyError } from "../../services/Validation"

const Delete = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { makeRequest } = useMakeRequest()
  const { isLoading } = useContext(AppContext)
  const breadcrumb = [
    {
      link: LISTINGS,
      caption: "Produits",
    },
    {
      link: LISTING_SHOW.replace(":id", id),
      caption: "#" + id,
    },
    {
      link: DELETE_LISTING.replace(":id", id),
      caption: "Supprimer le produit",
      active: true,
    },
  ]

  function abort() {
    navigate({ pathname: LISTING_SHOW.replace(":id", id) })
  }

  function onDelete() {
    makeRequest({
      method: "post",
      route: LISTING_DELETE.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      notify({ message: response.data.message })
      navigate({ pathname: LISTINGS })
    }
  }

  function onError(error) {
    notifyError(error)
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <Row>
        <Col md={6}>
          <Card>
            <Card.Header>Supprimer le produit #{id}</Card.Header>
            <Card.Body>
              <Alert variant="danger">
                Supprimer un produit est irreversible
              </Alert>
            </Card.Body>
            <Card.Footer>
              <ButtonList>
                <Button variant="light" onClick={abort} disabled={isLoading}>
                  Annuler
                </Button>
                <Button
                  variant="danger"
                  onClick={onDelete}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Supprimer
                </Button>
              </ButtonList>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Delete
