/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/orders/PaidBadge.js
Created On : 24 March 2022 14:00:50
*/

import React from "react"
import { Badge } from "react-bootstrap"

const PaidBadge = () => {
  return (
    <Badge pill bg="success" className="me-2">
      Payé <i className="fa-solid fa-check-circle"></i>
    </Badge>
  )
}

export default PaidBadge
