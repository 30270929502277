/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Referencement.js
Created On : 24 Juillet 2022 18:16:43
*/

import React from "react"
import { FormGroup } from "../../utilities"

const Referencement = ({
  seoTitle,
  seoDescription,
  setSeoTitle,
  setSeoDescription,
  slug,
  setSlug,
  errors = [],
}) => {
  function counter(item, min = null, max = null) {
    if (min && !max) {
      return `max ${min} caractères, il vous reste ${max - item.length}`
    }
    if (!min && max) {
      return `max ${max} caractères, il vous reste ${max - item.length}`
    }
    if (min && max) {
      return item.length < min
        ? `min ${min} caractères, il vous reste ${min - item.length}`
        : `max ${max} caractères, il vous reste ${max - item.length}`
    }
  }

  return (
    <>
      <FormGroup
        required={true}
        label="Titre SEO"
        type="text"
        max={55}
        value={seoTitle}
        onChange={setSeoTitle}
        error={errors.seo_title}
        text={counter(seoTitle, 20, 55)}
      />
      <FormGroup
        required={true}
        label="Slug"
        type="text"
        max={55}
        value={slug}
        onChange={setSlug}
        error={errors.slug}
      />
      <FormGroup
        as="textarea"
        required={true}
        label="Description"
        type="text"
        max={55}
        value={seoDescription}
        onChange={setSeoDescription}
        error={errors.seo_description}
        text={counter(seoDescription, 50, 150)}
        inputClassName="mh-100"
      />
    </>
  )
}

export default Referencement
