/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/form/Select.js
Created On : 14 May 2022 13:09:02
*/

import React from "react"
import { Form } from "react-bootstrap"

const Select = ({
  name,
  label,
  placeholder = "Aucun",
  value = "",
  onChange,
  disabled = false,
  error = null,
  options = [],
  text,
  required,
  className = "",
  inputClassName = "",
  id,
}) => {
  const setOnChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <Form.Group className={"form-group " + className}>
      {label && (
        <Form.Label className={required && "required"}>{label}</Form.Label>
      )}
      <Form.Select
        name={name}
        value={value ? value : ""}
        onChange={(e) => setOnChange(e)}
        placeholder={placeholder}
        disabled={disabled}
        isInvalid={error}
        id={id}
        className={inputClassName}
      >
        {!value && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map((option, k) => (
          <option key={k} value={option.value}>
            {option.caption}
          </option>
        ))}
      </Form.Select>
      {text && <Form.Text muted>{text}</Form.Text>}
      {error && (
        <div>
          <Form.Text className="text-danger">{error}</Form.Text>
        </div>
      )}
    </Form.Group>
  )
}

export default Select
