/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Dashboard.js
Created On : 12 May 2022 14:03:19
*/

import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Overview from "../components/dashboard/Overview"
import UsersChart from "../components/dashboard/UsersChart"
import { DASHBOARD } from "../routes/api"
import { useMakeRequest } from "../services/httpClient"
import { notifyErrors } from "../services/Validation"

const Dashboard = () => {
  const [overview, setOverview] = useState([])

  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: DASHBOARD,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setOverview(response.data.overview)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      <div className="">
        <Overview data={overview} />
      </div>
      <Row className="mb-3">
        <Col md={12}>
          <UsersChart />
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
