/**
 * @Numerik
 *
 * Auth reducer
 */

import * as types from "../types/auth"

const INITIAL_STATE = {
  cart: null,
  user: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, user: action.payload }
    case types.SET_CART:
      return { ...state, cart: action.payload }
    default:
      return state
  }
}

export default reducer
