/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/listings/create/Photos.js
Created On : 24 May 2022 17:01:23
*/

import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Button, FormGroup } from "../../utilities"

const Photos = ({ images, setImages, errors, isLoading }) => {
  //
  function openUploader() {
    let uploader = document.getElementById("image-uploader")
    uploader.click()
  }
  //
  function addImage(img) {
    if (images.length <= 0) {
      img.is_default = true
    }
    let imgs = [...images]
    imgs.push(img)
    setImages(imgs)
  }

  function removeItem(idx) {
    let imgs = [...images]
    imgs.splice(idx, 1)
    setImages(imgs)
  }

  function setDefault(idx) {
    let imgs = [...images]
    imgs.forEach((img) => {
      img.is_default = false
    })
    imgs[idx].is_default = true
    setImages(imgs)
  }

  const ImageDisplayer = ({ img, idx }) => {
    return (
      <div className="lcf--img-displayer">
        <img src={img.url} alt="" />
        {img.is_default && <span className="default-tag">Par défaut</span>}
        {!img.is_default && (
          <span className="set-default" onClick={() => setDefault(idx)}>
            Définir par defaut
          </span>
        )}
        <span className="remove-img" onClick={() => removeItem(idx)}>
          <i className="fa-solid fa-trash"></i>
        </span>
      </div>
    )
  }

  return (
    <div>
      <Card>
        <Card.Header>Photos</Card.Header>
        <Card.Body>
          <Button
            variant="primary"
            onClick={() => openUploader()}
            className="mb-1"
          >
            <i className="fa-solid fa-camera"></i> Ajouter
          </Button>
          {errors.photos && (
            <div>
              <span className="text-danger">{errors.photos}</span>
            </div>
          )}

          <FormGroup
            inputId="image-uploader"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={addImage}
          />
          <Row>
            {images.map((img, key) => (
              <Col md={3}>
                <ImageDisplayer idx={key} img={img} key={key} />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Photos
