/**
 * @Numerik
 */
import { createStore, applyMiddleware } from "redux"
import reducers from "../reducers"
import ReduxThunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "auth",
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, applyMiddleware(ReduxThunk))
let persistor = persistStore(store)
export { store, persistor }
