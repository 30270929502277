/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/listings/Show.js
Created On : 13 May 2022 18:29:31
*/

import React, { useEffect, useState } from "react"
import { Dropdown, Tab, Tabs } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { BreadCrumb, Button, NavOptions } from "../../components/utilities"
import {
  DELETE_LISTING,
  LISTINGS,
  LISTING_EDIT,
  LISTING_SHOW,
} from "../../routes"
import Infos from "../../components/listings/show/Infos"
import Inventory from "../../components/listings/show/Inventory"
import { SHOW_LISTING } from "../../routes/api"
import { notifyError } from "../../services/Validation"
import { useMakeRequest } from "../../services/httpClient"
import Photos from "../../components/listings/show/Photos"

const Show = () => {
  const { id } = useParams()
  const [listing, setListing] = useState(null)
  const { makeRequest } = useMakeRequest()
  const navigate = useNavigate()
  useEffect(() => {
    fetchData()
  }, [id])

  function fetchData() {
    makeRequest({
      route: SHOW_LISTING.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setListing(response.data.listing)
    }
  }

  function onError(error) {
    notifyError(error)
  }

  const breadcrumb = [
    {
      link: LISTINGS,
      caption: "Produits",
    },
    {
      link: LISTING_SHOW.replace(":id", id),
      caption: "#" + id,
      active: true,
    },
  ]

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <NavOptions>
        <Button variant="light" onClick={fetchData}>
          <i className="fa-solid fa-arrows-rotate"></i>
        </Button>
        <Dropdown>
          <Dropdown.Toggle variant="light" id="actions">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                navigate({ pathname: LISTING_EDIT.replace(":id", id) })
              }
            >
              Modifier
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigate({ pathname: DELETE_LISTING.replace(":id", id) })
              }
            >
              Supprimer
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </NavOptions>
      {listing ? (
        <Tabs
          defaultActiveKey="informations"
          id="listing-show"
          className="mb-3"
        >
          <Tab eventKey="informations" title="Informations">
            <Infos data={listing} />
          </Tab>
          <Tab eventKey="inventory" title="Inventaire">
            <Inventory data={listing} />
          </Tab>
          <Tab eventKey="photos" title="Photos">
            <Photos onSuccess={fetchData} listing={listing} />
          </Tab>
        </Tabs>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Show
