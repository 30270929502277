/**
 * @Numerik
 */

import * as types from "../types/auth"

//set the user
export const setUser = (user) => {
  return {
    type: types.SET_USER,
    payload: user,
  }
}

//set the cart id
export const setCart = (cart) => {
  return {
    type: types.SET_CART,
    payload: cart,
  }
}
