/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/dashboard/UsersChart.js
Created On : 30 Juillet 2022 18:59:39
*/

import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { useMakeRequest } from "../../services/httpClient"
import { notifyErrors } from "../../services/Validation"
import { API_DASHBOARD_FETCH_STATS_OVERVIEW } from "../../routes/api"
import { Button, ButtonList } from "../utilities"

const UsersChart = () => {
  const [list, setList] = useState(null)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  )

  const { makeRequest } = useMakeRequest()

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: API_DASHBOARD_FETCH_STATS_OVERVIEW,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setList(response.data)
    }
  }

  function onError(error) {
    notifyErrors(error)
  }

  return (
    <div>
      {list && (
        <>
          <div className="my-2">
            <ButtonList>
              <Button variant="light" onClick={() => fetchData()}>
                <i className="fa-solid fa-refresh"></i> Actualiser
              </Button>
            </ButtonList>
          </div>

          <Card>
            <Card.Header>Visiteurs</Card.Header>
            <Card.Body>
              <Line options={options} data={list} />
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  )
}
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Visiteurs",
    },
  },
}
export default UsersChart
