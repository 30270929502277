/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/ui/loader.js
Created On : 11 March 2022 19:12:04
*/

import React from "react"

const loader = ({ visible }) => {
  return (
    <div className={"is-loading--01 " + (visible ? " show " : " ")}>
      <div className="tp--inner-bar"></div>
    </div>
  )
}

export default loader
