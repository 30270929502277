/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/account/orders/Status.js
Created On : 23 March 2022 15:56:32
*/
import React from "react"

const Status = ({ status = [] }) => {
  return (
    <>
      <span className={status.color + " order-status--01"}>
        <span className={status.color + " o--dot"}></span>
        <span className="o--caption">{status.caption}</span>
      </span>
    </>
  )
}

export default Status
