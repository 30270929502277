/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/finances/currencies/Show.js
Created On : 14 May 2022 17:54:07
*/

import { Tab } from "bootstrap"
import React, { useState, useEffect } from "react"
import { Badge, Card, Col, Dropdown, Row, Table, Tabs } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import AddRate from "../../../components/finances/currencies/AddRate"
import {
  BreadCrumb,
  Button,
  FormGroup,
  NavOptions,
} from "../../../components/utilities"
import {
  EDIT_CURRENCY,
  FINANCES_CURRENCIES,
  SHOW_CURRENCY,
} from "../../../routes"
import { CURRENCY_SHOW, DELETE_EXCHANGE_RATE } from "../../../routes/api"
import { useMakeRequest } from "../../../services/httpClient"
import { notifyError } from "../../../services/Validation"

const Show = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [currency, setCurrency] = useState(null)
  const [exchangeRate, setExchangeRate] = useState([])
  const { makeRequest, makeAction } = useMakeRequest()
  const [createRate, setCreateRate] = useState(false)
  const breadcrumb = [
    {
      link: "/",
      caption: "Finances",
      active: true,
    },
    {
      link: FINANCES_CURRENCIES,
      caption: "Dévises",
    },
    {
      link: SHOW_CURRENCY.replace(":id", id),
      caption: "#" + id,
      active: true,
    },
  ]

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData(url = null) {
    makeRequest({
      route: CURRENCY_SHOW.replace(":id", id),
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 200) {
      setCurrency(response.data.currency)
      setExchangeRate(response.data.exchange_rate)
    }
  }

  function onError(error) {
    notifyError(error)
  }

  //
  function deleteRate(id) {
    makeAction({
      method: "post",
      route: DELETE_EXCHANGE_RATE.replace(":id", id),
      onSuccess: fetchData,
    })
  }

  return (
    <div>
      <BreadCrumb items={breadcrumb} />
      <NavOptions>
        <Button variant="light" onClick={() => fetchData()}>
          <i className="fa-solid fa-rotate-right"></i>
        </Button>
        {!currency?.is_main ? (
          <div>
            <Button variant="light" onClick={() => setCreateRate(true)}>
              Ajouter un taux
            </Button>
            {createRate ? (
              <AddRate
                currency={currency}
                visible={createRate}
                toggleVisible={setCreateRate}
              />
            ) : null}
          </div>
        ) : null}
        <Dropdown>
          <Dropdown.Toggle variant="light" id="actions">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                navigate({ pathname: EDIT_CURRENCY.replace(":id", id) })
              }
            >
              Modifier
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </NavOptions>
      <Card>
        {currency && (
          <Card.Body>
            <Row className="mb-3">
              <Col md={4}>
                <label>Status</label>
              </Col>
              <Col md={8}>
                <Badge pill bg={`${currency.is_active ? "success" : "dark"}`}>
                  {currency.is_active ? "Active" : "Inactif"}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>ID</label>
              </Col>
              <Col md={8}>
                <FormGroup value={currency.id} readOnly={true} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Dévise</label>
              </Col>
              <Col md={8}>
                <FormGroup value={currency.currency} readOnly={true} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Symbole</label>
              </Col>
              <Col md={8}>
                <FormGroup value={currency.symbol} readOnly={true} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Date d'ajout</label>
              </Col>
              <Col md={8}>
                <FormGroup value={currency.f_created_at} readOnly={true} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Dernière modification</label>
              </Col>
              <Col md={8}>
                <FormGroup value={currency.f_updated_at} readOnly={true} />
              </Col>
            </Row>
            {/* exchange rates */}
            {!currency.is_main ? (
              <Tabs
                defaultActiveKey="excchange-rate"
                id="excchange-rate"
                className="mb-3"
              >
                <Tab eventKey="excchange-rate" title="Taux">
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Unité par</th>
                        <th>Date d'ajout</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exchangeRate.map((rate) => (
                        <tr key={rate.id}>
                          <td>{rate.date}</td>
                          <td>{rate.rate}</td>
                          <td>{rate.f_created_at}</td>
                          <td>
                            <Badge
                              pill
                              bg="danger"
                              onClick={() => deleteRate(rate.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </Badge>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            ) : (
              <></>
            )}
          </Card.Body>
        )}
      </Card>
    </div>
  )
}

export default Show
