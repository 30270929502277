/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/ux/BreadCrumb.js
Created On : 12 May 2022 15:13:58
*/
import React from "react"
import { Breadcrumb } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const BreadCrumb = ({ items = [] }) => {
  const navigate = useNavigate()

  function onClick(e, link, active) {
    e.preventDefault()
    if (!active) {
      navigate({ pathname: link })
    }
  }

  return (
    <div className="mb-4">
      <Breadcrumb>
        {items.map((item, key) => (
          <Breadcrumb.Item
            onClick={(e) => onClick(e, item.link, item.active)}
            key={key}
            href={item.link}
            active={item.active}
          >
            {item.caption}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
}

export default BreadCrumb
