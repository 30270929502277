/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/utilities/form/ButtonList.js
Created On : 13 May 2022 20:56:19
*/
import React from "react"

const ButtonList = ({ children }) => {
  return <div className="btn-list-01">{children}</div>
}

export default ButtonList
