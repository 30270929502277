/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/Protected.js
Created On : 12 May 2022 14:01:48
*/

import React from "react"
import { Routes, Route } from "react-router-dom"
import * as appRoutes from "./index"

//layout
import LayoutMaster from "../layouts/Master"

//pages
import Dashboard from "../pages/Dashboard"
import SearchTermsPage from "../pages/dashboard/SearchTerms"

//listings
import AddListing from "../pages/listings/Add"
import ListingsManager from "../pages/listings/Manager"
import ListingShow from "../pages/listings/Show"
import ListingEdit from "../pages/listings/Edit"
import ListingDelete from "../pages/listings/Delete"
//brands
import Brands from "../pages/brands"
//categories
import ListingCategories from "../pages/listings/categories"
import ListingCategoryShow from "../pages/listings/categories/Show"
//Collections
import ListingCollections from "../pages/listings/collections"
import ListingCollectionShow from "../pages/listings/collections/Show"
//listings variations types
import ListingVariationTypes from "../pages/listings/variations-types"
import ListingVariationTypeShow from "../pages/listings/variations-types/Show"

//finances
//* Currencies
import FinancesCurrencies from "../pages/finances/currencies/index.js"
import FinancesCurrenciesAdd from "../pages/finances/currencies/Add"
import FinancesCurrencieShow from "../pages/finances/currencies/Show"
import FinancesCurrencieEdit from "../pages/finances/currencies/Edit"
//* Payment methods
import FinancesPaymentMethods from "../pages/finances/payement-methods"
import FinancesCreatePaymentMethods from "../pages/finances/payement-methods/Create"
//settings
//* Countries
import SettingsCountries from "../pages/settings/Countries"
import SettingsCountriesShow from "../pages/settings/countries/Show"
//* Shipping profiles
import SettingsShippingProfile from "../pages/settings/shippings"

//orders
import OrdersManger from "../pages/orders/Manage"
import ShowOrder from "../pages/orders/Show"

//errors
import Error404 from "../pages/errors/404"

const Protected = () => {
  return (
    <LayoutMaster>
      <Routes>
        <Route path={appRoutes.DASHBOARD} element={<Dashboard />} />
        <Route
          path={appRoutes.STATS_SEARCH_TERMS}
          element={<SearchTermsPage />}
        />
        {/* orders */}
        <Route
          path={appRoutes.ORDERS_MANAGE_ROUTE}
          element={<OrdersManger />}
        />
        <Route path={appRoutes.ORDERS_SHOW_ROUTE} element={<ShowOrder />} />
        {/* listings */}
        <Route path={appRoutes.ADD_LISTING} element={<AddListing />} />
        <Route path={appRoutes.LISTINGS} element={<ListingsManager />} />
        <Route path={appRoutes.LISTING_SHOW} element={<ListingShow />} />
        <Route path={appRoutes.LISTING_EDIT} element={<ListingEdit />} />
        <Route path={appRoutes.DELETE_LISTING} element={<ListingDelete />} />
        {/* listing categories */}
        <Route
          path={appRoutes.CATEGORIES_ROUTE}
          element={<ListingCategories />}
        />
        <Route
          path={appRoutes.CATEGORY_SHOW_ROUTE}
          element={<ListingCategoryShow />}
        />
        {/* Brandss */}
        <Route path={appRoutes.BRANDS_ROUTE} element={<Brands />} />
        {/* listing collections */}
        <Route
          path={appRoutes.COLLECTIONS_ROUTE}
          element={<ListingCollections />}
        />
        <Route
          path={appRoutes.COLLECTIONS_SHOW_ROUTE}
          element={<ListingCollectionShow />}
        />
        {/* variations types */}
        <Route
          path={appRoutes.LISTINGS_VARIATIONS_TYPES}
          element={<ListingVariationTypes />}
        />
        <Route
          path={appRoutes.LISTINGS_VARIATIONS_TYPE_SHOW}
          element={<ListingVariationTypeShow />}
        />

        {/* seetings */}
        {/* ** Countries** */}
        <Route
          path={appRoutes.COUNTIRES_REGIONS}
          element={<SettingsCountries />}
        />

        <Route
          path={appRoutes.COUNTIRES_REGIONS_SHOW}
          element={<SettingsCountriesShow />}
        />
        {/* ** Profile de livraison ** */}
        <Route
          path={appRoutes.SHIPPING_PROFILES}
          element={<SettingsShippingProfile />}
        />
        {/* finances */}
        {/* ** FINANCES CURRENCIES ** */}
        <Route
          path={appRoutes.FINANCES_CURRENCIES}
          element={<FinancesCurrencies />}
        />
        <Route
          path={appRoutes.ADD_CURRENCY}
          element={<FinancesCurrenciesAdd />}
        />
        <Route
          path={appRoutes.SHOW_CURRENCY}
          element={<FinancesCurrencieShow />}
        />
        <Route
          path={appRoutes.EDIT_CURRENCY}
          element={<FinancesCurrencieEdit />}
        />
        {/* ** FINANCES PAYMENT METHODS ** */}
        <Route
          path={appRoutes.FINCANCES_PAYMENT_METHODS_ROUTE}
          element={<FinancesPaymentMethods />}
        />
        <Route
          path={appRoutes.FINCANCES_ADD_PAYMENT_METHODS_ROUTE}
          element={<FinancesCreatePaymentMethods />}
        />
        {/* errors */}
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </LayoutMaster>
  )
}

export default Protected
